export const qrCodeTickets = {
  cardriverDetailsTitle: 'Announce vehicle driver',
  carDriverDetailsDescription:
    'If you are not responsible for the circulation fee, you can have the circulation fee transferred to the driver of the vehicle within 20 days.',
  firstName: 'First name',
  lastName: 'Last name',
  streetName: 'Street',
  streetNumber: 'Street number',
  zipCode: 'ZIP code',
  city: 'City',
  email: 'Email',
  carDriverInfo: 'All fields marked with an asterisk must be completed.',
  legalInformation: 'Informations supplémentaires et légales',
  legalInformationElement:
    "SSi votre plaque d'immatriculation est bloquée, vous avez 24 heures pour nous fournir vos informations de propriétaire via le formulaire en ligne ou par email à info@falsch-parker.ch. Sinon, des frais supplémentaires peuvent être engagés.\n\nStationnement illégal sur propriété privée (Art. 641 + 926 + 927 + 928 du Code Civil Suisse) par cession conformément à l'Art. 164 et suivants du Code des Obligations Suisse.",
  next: 'Next',
  back: 'Back',
  chedckDetailsTitle: 'Check and send details',
  checkDetailsDescription: 'Please check the details.',
  ticketNumberQR: 'Numéro de remboursement des frais',
  plate: 'Number plate',
  countryCode: 'Country code',
  notes: 'Notes',
  fineAmount: 'Fine amount',
  zurichCityPolice: 'Zurich City Police',
  placeAndDateOfTheOffense: 'Place and date of the offense',
  streetAndStreetNumber: 'Street, number',
  zipCodeAndCity: 'ZIP code, city',
  timeAndDate: 'Date, time',
  personalDetailsOfTheDriver: 'Personal details of the driver',
  name: 'Last name / First name',
  addressSuffix: 'Address suffix',
  checkDetailsInfo:
    'I hereby confirm that the information I have provided is correct. Anyone who, against their better judgment, accuses a person who is not guilty of a criminal offense to the authorities with the intention of bringing criminal proceedings against them is liable to prosecution (Art. 303 Swiss Criminal Code).*',
  send: 'Send',
  failedViewTitle: 'Payment failed :(',
  failedViewSubtitle: 'Please try again or contact us',
  failedViewButton: 'Try again',
  objectionRaiseTitle: 'Raise an objection',
  objectionRaiseSubtitle:
    'Do you believe that the redistribution compensation is unjustified or incorrect?',
  objectionRaiseDescription:
    'If your objection is related to the use of a parking app, please enclose proof of the relevant parking transaction from the app provider with your objection or upload it as a file. We will need this in any case in order to check your objection.',
  note: 'Your objection',
  notePlaveholder:
    'Please give reasons for your objection (maximum 2000 characters)',
  objectionRaiseUpload: 'Attach a file',
  addFile: 'Add file',
  addFileDesc: '(Max. 5 * 10MB, permitted formats: pdf, png, jpg, bmp)',
  successPaymentTitle: 'Your payment was successful!',
  successPaymentSubtitle: 'Thank you for your payment',
  paymentInfoTitle: 'Payment / Payment information',
  paymentInfoSubtitle:
    'You have the following options for paying the handling fee:',
  paymentMethod: 'Credit card, Postfinance Card, Twint or e-banking',
  pay: 'Pay',
  eBanking: 'E-Banking',
  bankAccountNumber: 'Bank account number',
  yourReferenceNo: 'Your reference number',
  amount: 'Amount',
  inFavorOf: 'In favor of',
  paymentSlip: 'Payment slip / payment form',
  paymentSlipDesc:
    'If the parking fine has not been paid 20 days after the parking offense, we will automatically send the person responsible a parking fine with a payment slip.',
  acceptAGB: 'I accept the AGB`s',
  congratulations: 'Congratulations!',
  successCarOwnerAddingSubtitle: 'The driver`s information was transmitted',
  successObjectionAddingSubtitle:
    'Your objection has been successfully submitted',
  ticketDetailsTitle: 'Details on the circulation compensation',
  ticketDetaildNumber: 'Numéro de ticket',
  parkingOffendersEmployees: 'Parking offenders Employees',
  placeAndDateOfTheParkingViolation: 'Place and date of the parking violation',
  objection: 'Objection',
  payNow: 'Pay now',
  ticketTitle: "Veuillez entrer le numéro de plaque d'immatriculation:",
  or: 'Et',
  plateNumber: "Plaque d'immatriculation",
  noTicketTitle: 'No compensation found',
  noTicketDesc:
    'Unfortunately, we have not found any circulation compensation with the information entered. Please scan the QR code again and check the UE no.',
};

import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ContractPoint } from '../ContractPoint/ContractPoint';
import { contractPointsData } from 'data/contractPoints';
import { ContractSignatureContainer } from '../ContractSignatureContainer/ContractSignatureContainer';
import { useContext } from 'react';
import { appContext } from 'views/App';

const StyledHeading = styled(Heading)`
  padding-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 16px;
`;
const StyledParagraph = styled(Paragraph)`
  padding: 10px 0;
  white-space: pre-line;
`;

export const ContractMainContent = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const utc = new Date().toJSON().slice(0, 10);
  return (
    <div>
      <StyledHeading color="var(--black)" weight="bold" textAlign="center">
        {t('contractName')}
      </StyledHeading>
      <StyledParagraph color="var(--black)" dimension="s" textAlign="center">
        {t('from')} {utc} <br />
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s" textAlign="center">
        {t('inBetween')}
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s">
        {userData?.firstName} {userData?.lastName}
        <br />
        {userData?.companyName} <br />
        {userData?.companyAddress} <br />
        {userData?.email}
        <br />
        {userData?.phonePrefix} {userData?.phoneNumber}
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s" textAlign="right">
        ({t('hereinafter')} &quot;<strong>{t('client')}</strong>&quot;)
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s" textAlign="center">
        {t('and')}
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s">
        {t('contractorAddress')}
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s" textAlign="right">
        ({t('hereinafter')} &quot;<strong>{t('contractor')}</strong>&quot;)
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s" textAlign="center">
        {t('contractSubtitle')}
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s">
        <strong>{t('preamble')}</strong>
        <br />
        <br />
        {t('constractFirstParagraphFirstPart')}
      </StyledParagraph>
      <StyledParagraph color="var(--black)" dimension="s">
        {t('constractFirstParagraphSecondPart')}
      </StyledParagraph>
      <ContractPoint
        key={contractPointsData[0].title}
        point={1}
        title={contractPointsData[0].title}
        content={contractPointsData[0].content}
        subcontent={contractPointsData[0].subcontent}
      />
      {contractPointsData.slice(1).map((point, index) => (
        <ContractPoint
          key={point.title}
          point={index + 2}
          title={point.title}
          content={point.content}
          subcontent={point.subcontent}
          pointsSubcontent={point.pointsSubcontent}
          bottomContent={point?.bottomContent}
        />
      ))}
      <StyledParagraph color="var(--black)" dimension="s">
        {t('zurich')}, {utc}
      </StyledParagraph>
      <ContractSignatureContainer />
    </div>
  );
};

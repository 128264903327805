import { ContractPointProps } from 'views/Contract/ContractPoint/ContractPoint';

export const contractPointsData: ContractPointProps[] = [
  {
    title: 'contractFirstPointTitle',
    content: 'contractFirstPointContent',
  },
  {
    title: 'contractSecondPointTitle',
    subcontent: [
      'contractSecontPointSubcontentOne',
      'contractSecontPointSubcontentTwo',
    ],
  },
  {
    title: 'contractThirdPointTitle',
    subcontent: [
      'contractThirdPointSubcontentOne',
      'contractThirdPointSubcontentTwo',
    ],
    bottomContent: 'contractThirdPointBottomContent',
  },
  {
    title: 'contractFourthPointTitle',
    content: 'contractFourthPointContent',
  },
  {
    title: 'contractFifthPointTitle',
    content: 'contractFifthPointContent',
  },
  {
    title: 'contractSixthPointTitle',
    content: 'contractSixthPointContent',
  },
  {
    title: 'contractSeventhPointTitle',
    content: 'contractSeventhPointContent',
  },
  {
    title: 'contractEighthPointTitle',
    content: 'contractEighthPointContent',
  },
  {
    title: 'contractNinthPointTitle',
    content: 'contractNinthPointContent',
  },
  {
    title: 'contractTenthPointTitle',
    content: 'contractTenthPointContent',
  },
  {
    title: 'contractEleventhPointTitle',
    content: 'contractEleventhPointContent',
  },
  {
    title: 'contract12PointTitle',
    content: 'contract12PointContent',
  },
  {
    title: 'contract13PointTitle',
    content: 'contract13PointContent',
  },
  {
    title: 'contract14PointTitle',
    content: 'contract14PointContent',
  },
  {
    title: 'contract15PointTitle',
    content: 'contract15PointContent',
  },
];

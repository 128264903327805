import { ComplexButton } from 'components/ComplexButton/ComplexButton';
import { TicketIcon } from 'components/Icons/TicketIcon/TicketIcon';
import { TruckIcon } from 'components/Icons/TruckIcon/TruckIcon';
import { Layout } from 'components/Layout/Layout';
import { Splash } from 'components/Splash/Splash';
import { useAccountType } from 'hooks/useAccountType';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import greenCar from 'assets/img/green-car-medium.png';
import whiteQuestion from 'assets/img/whitequestion.svg';
import blackQuestion from 'assets/img/blackquestion.svg';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'context/ThemeContext';
import { HomeMenuSkeleton } from 'skeletons/HomeMenuSkeleton';
import { ClockIcon } from 'components/Icons/ClockIcon/ClockIcon';
import { useLazyQuery } from '@apollo/client';
import { USER_REPORTS_ALL } from 'graphql/queries/userReportsAll';
import { ReportProps } from 'types/Report';
import { DashboardUserBar } from './DashboardUserBar/DashboardUserBar';
import { GasStationIcon } from 'components/Icons/GasStationIcon/GasStationIcon';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  width: 100%;
  min-height: 550px;
  padding-top: 10px;
  .homeComplexButton {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .inProgressButton {
    border-color: ${(props) => props.theme.primary};
  }
`;

const StyledBottomContainer = styled.div`
  height: 66px;
`;

export const Dashboard = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const { isThisAccountType: isSuperAdminAccount } =
    useAccountType('superAdmin');
  const { isDarkMode } = useContext(ThemeContext);
  const [inProgressReports, setInProgressReports] = useState<ReportProps[]>([]);
  const [userReportsAll, { data, loading }] = useLazyQuery(USER_REPORTS_ALL, {
    fetchPolicy: 'network-only',
  });
  const handleInProgressClick = () => {
    if (inProgressReports.length > 1) {
      navigate('/history/active');
    } else {
      navigate(`/active-report/${inProgressReports[0].hash}`);
    }
  };
  useEffect(() => {
    if (isUserAccount) userReportsAll({ variables: { order: 'DESC' } });
  }, [isUserAccount]);

  useEffect(() => {
    if (!data) return;
    const reports = data.userReportsAll.filter(
      (report) =>
        report.status === 'new' ||
        report.status === 'accepted' ||
        report.status === 'inProgress',
    );
    setInProgressReports(reports);
  }, [data]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    sessionStorage.removeItem('qrCodeId');
  }, []);

  return (
    <Layout
      isMenuBar
      topContentPosition="center"
      mode="medium"
      topContent={
        <img
          src={userData?.appSettings?.logo || greenCar}
          alt="Green Car"
          width={328}
        />
      }
      userBarContent={<DashboardUserBar />}
      outsideElements={<Splash />}
    >
      <StyledContainer>
        {userData ? (
          <div>
            {isUserAccount && inProgressReports[0] && (
              <ComplexButton
                isWithArrowIcon
                mode="light"
                title={t('inProgressButton')}
                subtitle={t('inProgressSubtitle')}
                icon={<ClockIcon fill="var(--black)" />}
                onComplexBtnClick={handleInProgressClick}
                className={'homeComplexButton inProgressButton'}
                id="openInProgressReports"
              />
            )}
            <ComplexButton
              isWithArrowIcon
              mode="light"
              title={t('carTowing')}
              subtitle={t('carTowingSubtitle')}
              icon={<TruckIcon fill="var(--black)" />}
              onComplexBtnClick={() => {
                !userData?.verified && setReportDetails({ id: 1 });
                userData?.verified
                  ? navigate('/add-report/1')
                  : navigate('/failed-report');
              }}
              className={'homeComplexButton'}
              id="createTowingReport"
            />
            <ComplexButton
              isWithArrowIcon
              mode="light"
              title={t('parkingTicket')}
              subtitle={t('parkingTicketSubtitle')}
              icon={<TicketIcon />}
              onComplexBtnClick={() => {
                !userData?.verified && setReportDetails({ id: 2 });
                userData?.verified
                  ? navigate('/add-report/2')
                  : navigate('/failed-report');
              }}
              className={'homeComplexButton'}
              id="createTicket"
            />
            {!isUserAccount && (
              <ComplexButton
                isWithArrowIcon
                mode="light"
                title={t('registerPlateCheck')}
                subtitle={t('registerPlateCheckSubtitle')}
                icon={
                  <img
                    src={isDarkMode ? whiteQuestion : blackQuestion}
                    alt="Question"
                  />
                }
                onComplexBtnClick={() => {
                  navigate('/plate-check');
                }}
                className={'homeComplexButton'}
                id="plateChack"
              />
            )}
            {(isSuperAdminAccount ||
              (isUserAccount && userData?.gasStationUser)) && (
              <ComplexButton
                isWithArrowIcon
                mode="light"
                title={t('gasStationButtonTitle')}
                subtitle={t('gasStationButtonSubtitle')}
                icon={<GasStationIcon size="24" />}
                onComplexBtnClick={() => {
                  navigate('/add-report/3');
                }}
                className={'homeComplexButton'}
                id="createGasStationReport"
              />
            )}
            <div style={{ height: '20px' }}></div>
          </div>
        ) : (
          <HomeMenuSkeleton />
        )}
        <StyledBottomContainer />
      </StyledContainer>
    </Layout>
  );
};

import { useMutation } from '@apollo/client';
import { Anchor } from 'components/Anchor/Anchor';
import { Layout } from 'components/Layout/Layout';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { useAuthContext } from 'context/auth';
import { Input } from 'components/Input/Input';
import { passwordValidation } from 'data/regexPatterns';
import { LOGIN } from 'graphql/mutations/login';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import greenCarFull from 'assets/img/green-car-full.png';
import { getRole } from 'utils/getRole';
import { Popup } from 'components/Popup/Popup';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { Splash } from 'components/Splash/Splash';
import { LOGOUT } from 'graphql/mutations/logout';
import { deleteCookie } from 'utils/deleteCookie';
import { appContext } from 'views/App';

const StyledContainer = styled.div<{ height: number }>`
  height: 100%;
  min-height: 420px;
  padding-top: 130px;
  .signInButton {
    background-color: var(--green);
  }
`;
const StyledForm = styled.form`
  padding-bottom: 35px;
  .signUpInput {
    margin-bottom: 10px;
  }
`;
const StyledAnchorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0 35px;
`;
const StyledErrorContainer = styled.div`
  height: 15px;
`;

export const SignIn = () => {
  const appContextStore = useContext(appContext);
  const setUserData = appContextStore?.setUserData;
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;

  const [isLoginDataIncorrect, setIsLoginDataIncorrect] =
    useState<boolean>(false);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const [isErrorPopupOpened, setIsErrorPopupOpened] = useState<boolean>(false);
  const { setIsSignedIn, setAuthToken } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { windowHeight } = useWindowHeight();
  const height = windowHeight - 115;
  const methods = useForm({
    mode: 'onSubmit',
  });
  const { handleSubmit, getValues, watch } = methods;

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: (result) => {
      setAuthToken(result?.login.accessToken);
      const role = getRole(result?.login.user.rank);
      setUserData({ ...result?.login.user, role });
      document.cookie = `PHPSESSID=${result?.login.accessToken}; path=/`;
      setIsLoading(false);
      if (result?.login?.user?.rank === '1') {
        setIsSignedIn(true);
        navigate('/');
      } else {
        setIsPopupOpened(true);
      }
    },
    onError: (error) => {
      if (error) setIsLoginDataIncorrect(true);
      throw new Error(error.message);
    },
  });

  const [logout, { loading: logoutLoading }] = useMutation(LOGOUT, {
    onCompleted: () => {
      setIsSignedIn(false);
      deleteCookie('PHPSESSID');
      setAuthToken(null);
      setIsLoading(false);
      setUserData(null);
    },
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const handleLogout = () => {
    logout();
    setIsPopupOpened(false);
  };

  const onSubmit = () => {
    (document.activeElement as HTMLElement).blur();
    login({
      variables: {
        email: getValues('email').toLowerCase(),
        password: getValues('password'),
      },
    });
  };

  const handleGPSConfirm = async () => {
    setIsPopupOpened(false);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setIsSignedIn(true);
          navigate('/');
        },
        (error) => {
          if (error) setIsErrorPopupOpened(true);
        },
      );
    } else {
      setIsErrorPopupOpened(true);
    }
  };

  useEffect(() => {
    setIsLoading(loading || logoutLoading);
  }, [loading, logoutLoading]);

  useEffect(() => {
    setIsLoginDataIncorrect(false);
  }, [watch('email'), watch('password')]);

  return (
    <Layout
      isWithMapBackground
      mode="standard"
      topContent={<img src={greenCarFull} />}
      topContentPosition="center"
      isWithAppWrapper={false}
      outsideElements={
        <div>
          <Splash />
          <Popup
            isPopupOpened={isPopupOpened}
            type="action"
            confirmText={t('turnOnGPS')}
            content={t('gpsPopupContent')}
            declineText={t('logout')}
            isCloseButton={false}
            onConfirm={handleGPSConfirm}
            onDecline={handleLogout}
          />
          <Popup
            isPopupOpened={isErrorPopupOpened}
            type="info"
            content={t('gpsErrorPopupContent')}
            onDecline={() => setIsErrorPopupOpened(false)}
          />
        </div>
      }
    >
      <StyledContainer height={height}>
        <Heading color="var(--green)" dimension="xl">
          {t('signinTitle')}
        </Heading>
        <FormProvider {...methods}>
          <StyledForm onSubmit={handleSubmit(onSubmit)} id="loginForm">
            <Input
              name="email"
              label={t('emailLabel')}
              placeholder={t('emailPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              bottomMarting="10"
            />
            <PasswordInput
              placeholder={t('passwordPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              validation={passwordValidation}
              validationErrorMessage={t('passwordValidationError')}
              bottomMarting="10"
            />
            <StyledAnchorContainer>
              <StyledErrorContainer>
                {isLoginDataIncorrect ? (
                  <Paragraph color="var(--error)" dimension="s" weight="normal">
                    {t('loginError')}
                  </Paragraph>
                ) : (
                  <></>
                )}
              </StyledErrorContainer>
              <Anchor
                linkTitle={t('signinForgotPassword')}
                link="/forgot-password"
                color="white-always"
                linkWeight="regular"
                textColor="white-text"
              />
            </StyledAnchorContainer>

            <Button type="submit" className="signInButton">
              {t('signin')}
            </Button>
          </StyledForm>
        </FormProvider>
        <Anchor
          link="/sign-up"
          linkTitle={t('signUp')}
          label={t('signUpLabel')}
          size="s"
          textAlign="center"
          textColor="white-text"
        />
      </StyledContainer>
    </Layout>
  );
};

import { CircleIcon } from 'components/Icons/CircleIcon/CircleIcon';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledAccordionElement = styled.li`
  background-color: var(--grey-opacity);
  height: 61px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-radius: 12px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .elementText {
    margin-right: 5px;
  }
`;

const StyledElementTitle = styled.span`
  font-weight: 400;
  font-size: var(--text-s);
`;

interface AccordionElementProps {
  element: string;
  isElementSelected?: boolean;
  onTitleClick?: (element: string) => void;
  isAllElementClick?: boolean;
  onElementClick?: (element: string) => void;
  accordionElementTitle?: string;
  isWithSelectIcon?: boolean;
  addButtonTitle?: string;
  onAddButtonClick?: () => void;
}

export const AccordionElement = ({
  element,
  isElementSelected,
  onTitleClick,
  isAllElementClick,
  onElementClick,
  accordionElementTitle,
  isWithSelectIcon,
}: AccordionElementProps) => {
  const { t } = useTranslation();
  return (
    <StyledAccordionElement
      onClick={() =>
        isAllElementClick && onElementClick && onElementClick(element)
      }
    >
      <Paragraph
        color="var(--black)"
        dimension="l"
        weight="bolder"
        textTransform="capitalize"
        onClick={() => {
          onTitleClick && onTitleClick(element);
        }}
        className="elementText"
      >
        {accordionElementTitle && (
          <StyledElementTitle>
            {accordionElementTitle}
            <br />
          </StyledElementTitle>
        )}
        {t(element)}
      </Paragraph>
      {isWithSelectIcon && (
        <CircleIcon
          fill={isElementSelected ? 'var(--black)' : 'var(--white)'}
          onIconClick={() => !isAllElementClick && onElementClick(element)}
        />
      )}
    </StyledAccordionElement>
  );
};

import { useContext, useEffect, useState } from 'react';
import { useAccountType } from './useAccountType';
import { useLazyQuery } from '@apollo/client';
import { GET_NEW_NOTIFICATION } from 'graphql/queries/getNewNotification';
import { GET_NEW_NOTIFICATION_TIME } from 'data/updateDriverLocalizationTime';
import { appContext } from 'views/App';

export const useNewNotification = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setNewNotification = appContextStore?.setNewNotification;

  const [interval, setInterval] = useState<number>(0);
  const { isThisAccountType } = useAccountType('user');

  const [getNewNotification] = useLazyQuery(GET_NEW_NOTIFICATION, {
    onCompleted: (data) => {
      setTimeout(() => {
        setInterval((prev) => prev + 1);
      }, GET_NEW_NOTIFICATION_TIME);
      if (!data) return;
      if (data.getNewNotification) {
        const newNotification = {
          id: data?.getNewNotification?.id,
          text: data?.getNewNotification?.text,
          textEn: data?.getNewNotification?.textEn,
          reportHash: data?.getNewNotification?.report.hash,
        };
        setNewNotification({ ...newNotification });
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!userData) return;
    if (isThisAccountType) return;
    getNewNotification();
  }, [isThisAccountType, interval]);

  return;
};

import { Dropdown } from '../Dropdown/Dropdown';
import { StyledContainer, Input } from '../Input/Input';
import styled from 'styled-components/macro';
import { IconButton } from '../IconButton/IconButton';
import { SearchIcon } from '../Icons/SearchIcon/SearchIcon';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { appContext } from 'views/App';
import {
  ACTIVE_STATUSES,
  ALL_STATUSES,
  CLIENT_REPORT_STATUSES,
  CLIENT_STATUSES,
  GAS_STATION_REPORT_STATUSES,
  INACTIVE_STATUSES,
} from 'data/searchBarStatuses';

interface SearchBarProps {
  handleStatus?: (status: string) => void;
  isWithStatusDropdown?: boolean;
  isAllStatuses?: boolean;
  isDeactiveStatuses?: boolean;
  isClientsList?: boolean;
  isClientsReports?: boolean;
  isGasStationReports?: boolean;
}

const StyledSearchBar = styled(StyledContainer)`
  background-color: var(--white-opacity);
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  .input {
    margin-left: 10px;
    width: 100%;
  }
  .searchDropdown {
    margin-left: 0;
  }
`;
const StyledDropdownContainer = styled.div`
  justify-self: flex-end;
`;

export const SearchBar = ({
  handleStatus,
  isWithStatusDropdown = true,
  isAllStatuses = true,
  isDeactiveStatuses = false,
  isClientsList = false,
  isClientsReports = false,
  isGasStationReports = false,
}: SearchBarProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const options =
    (isClientsReports && CLIENT_REPORT_STATUSES) ||
    (isClientsList && CLIENT_STATUSES) ||
    (isDeactiveStatuses && INACTIVE_STATUSES) ||
    (isGasStationReports && GAS_STATION_REPORT_STATUSES) ||
    (isAllStatuses && ALL_STATUSES) ||
    ACTIVE_STATUSES;
  return (
    <StyledSearchBar>
      <IconButton>
        <SearchIcon stroke={userData?.appSettings?.primary_color} />
      </IconButton>
      <Input
        placeholder={t(
          isClientsReports
            ? 'clientReportsSearchPlaceholder'
            : isClientsList
            ? 'clientsSearchPlaceholder'
            : 'searchPlaceholder',
        )}
        name="search"
        mode="transparent"
        inputClassName="input"
        width={'100%'}
      />
      {isWithStatusDropdown && (
        <StyledDropdownContainer>
          <Dropdown
            options={options}
            mode="secondary"
            handleSelectedOption={handleStatus}
          />
        </StyledDropdownContainer>
      )}
    </StyledSearchBar>
  );
};

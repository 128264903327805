import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { MapIcon } from 'components/Icons/MapIcon/MapIcon';
import { Layout } from 'components/Layout/Layout';
import { Input } from 'components/Input/Input';
import { Map } from 'components/Map/Map';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useEffect, useState, MouseEvent, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Position } from 'types/general';
import { getAddress } from 'utils/getAddress';
import { getPosition } from 'utils/getPosition';
import { Popup } from 'components/Popup/Popup';
import { useMutation } from '@apollo/client';
import { DRIVER_PLATE_CHECK } from 'graphql/mutations/driverPlateCheck';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { appContext } from 'views/App';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledContainer = styled.div`
  .heading {
    margin-bottom: 16px;
  }
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  .cancelButton {
    margin-right: 16px;
  }
`;

export const PlateCheck = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setCheckedPlate = appContextStore?.setCheckedPlate;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const [position, setPosition] = useState<Position | undefined>(undefined);
  const [isAddressCorrect, setIsAddressCorrect] = useState<boolean>(true);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { windowHeight } = useWindowHeight();
  const navigate = useNavigate();
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, setValue, getValues, watch } = methods;

  const [driverPlateCheck, { loading }] = useMutation(DRIVER_PLATE_CHECK, {
    onCompleted: (data) => {
      setIsLoading(false);
      if (data.driverPlateCheck === 'This car is not allowed to park here') {
        navigate('/plate-status/2');
      } else if (data.driverPlateCheck === 'This car is allowed to park here') {
        navigate('/plate-status/1');
      }
    },
    onError: (error) => {
      if (error.message === 'This car is allowed to park here') {
        navigate('/plate-status/1');
      } else {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      }
    },
  });

  const onSubmit = () => {
    if (isAddressCorrect) {
      setCheckedPlate(getValues('licensePlate'));
      setIsConfirmPopupOpened(true);
    }
  };

  const handleLocationClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setPosition([latitude, longitude]);
        const address = await getAddress([latitude, longitude]);
        if (address) {
          setValue('reportStreet', address.street);
          setValue('reportStreetNumber', address.streetNumber);
          setValue('reportZipCode', address.zipCode);
          setValue('reportCity', address.city);
        }
      });
    }
  };

  const handleGetPosition = async () => {
    const address = `${getValues('reportStreet')}+${getValues(
      'reportStreetNumber',
    )}+, +${getValues('reportZipCode')}+${getValues('reportCity')}`;
    const encodeAddress = encodeURI(address);
    const addressData = await getPosition(encodeAddress);
    if (addressData) {
      setIsAddressCorrect(true);
      setPosition([addressData.lat, addressData.lng]);
    } else {
      setIsAddressCorrect(false);
    }
  };

  const handleConfirmClick = () => {
    setIsConfirmPopupOpened(false);
    if (position)
      driverPlateCheck({
        variables: {
          plate: getValues('licensePlate'),
          latitude: position[0],
          longitude: position[1],
        },
      });
  };

  useEffect(() => {
    const street = getValues('reportStreet');
    const streetNumber = getValues('reportStreetNumber');
    const zipCode = getValues('reportZipCode');
    const city = getValues('reportCity');
    if (street && streetNumber && zipCode && city) {
      handleGetPosition();
    }
  }, [
    watch('reportStreet'),
    watch('reportStreetNumber'),
    watch('reportZipCode'),
    watch('reportCity'),
  ]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const license = watch('licensePlate').toUpperCase().replaceAll(' ', '');
    setValue('licensePlate', license);
  }, [watch('licensePlate')]);

  return (
    <Layout
      isBackBtn
      mode="medium"
      backgroundContent={
        <Map
          height={`calc(100vh - ${windowHeight - 295}px)`}
          position={position ? position : undefined}
        />
      }
      outsideElements={
        <Popup
          isPopupOpened={isConfirmPopupOpened}
          type="action"
          content={t('formPopupValidation')}
          declineText="cancel"
          onDecline={() => setIsConfirmPopupOpened(false)}
          onConfirm={handleConfirmClick}
          confirmButtonId="confirmPlateCheck"
        />
      }
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id="addPlateCheckData">
          <StyledContainer>
            <Heading color="var(--black)">
              {t('parkedCarRegisterPlateCheck')}
            </Heading>
            <Input
              name="licensePlate"
              label={t('licensePlate')}
              mode="light"
              placeholder={t('licensePlatePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              bottomMarting="16"
            />
            <Heading color="var(--black)" className="heading">
              {t('yourLocation')}
            </Heading>
            <Input
              name="reportStreet"
              label={t('street')}
              mode="light"
              placeholder={t('streetPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              icon={
                <MapIcon
                  fill={userData?.appSettings?.primary_color}
                  stroke={userData?.appSettings?.primary_color}
                />
              }
              onIconClick={handleLocationClick}
              type="text"
              bottomMarting="16"
            />
            <Input
              name="reportStreetNumber"
              label={t('streetNumber')}
              mode="light"
              placeholder={t('streetNumberPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              bottomMarting="16"
            />
            <Input
              name="reportZipCode"
              label={t('zipCode')}
              mode="light"
              placeholder={t('zipCodePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              bottomMarting="16"
            />
            <Input
              name="reportCity"
              label={t('city')}
              mode="light"
              placeholder={t('cityPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              bottomMarting="16"
            />
            {!isAddressCorrect && (
              <Paragraph color="var(--error)">{t('addressNotFound')}</Paragraph>
            )}
          </StyledContainer>
          <StyledButtonsContainer>
            <Button
              mode="inverted"
              onBtnClick={() => navigate(-1)}
              className="cancelButton"
            >
              {t('cancelButton')}
            </Button>
            <Button type="submit">{t('check')}</Button>
          </StyledButtonsContainer>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};

import { useLazyQuery, useMutation } from '@apollo/client';
import { Layout } from 'components/Layout/Layout';
import { DISPLAY_ALL_NOTIFICATIONS } from 'graphql/mutations/displayAllNotifications';
import { USER_NOTIFICATIONS } from 'graphql/queries/userNotifications';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ReportNotification } from 'types/Notification';
import { NotificationsHeading } from './NotificationsHeading/NotificationsHeading';
import { NotificationList } from './NotificationList/NotificationList';
import { NoNotification } from './NoNotification/NoNotification';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  .noNotificationsTitle {
    padding-top: 50px;
  }
`;

export const Notifications = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [userNotificationsData, setUserNotifications] = useState<
    ReportNotification[] | null
  >(null);
  const [orderBy, setOrderBy] = useState<string | null>('DESC');
  const [userNotifications, { loading }] = useLazyQuery(USER_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setUserNotifications(data?.userNotifications),
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });
  const [displayAllNotifications] = useMutation(DISPLAY_ALL_NOTIFICATIONS);

  useEffect(() => {
    displayAllNotifications();
  }, []);

  useEffect(() => {
    userNotifications({ variables: { orderBy: 'DESC' } });
  }, []);

  useEffect(() => {
    if (!orderBy) return;
    userNotifications({ variables: { orderBy } });
  }, [orderBy]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('notifications')}
    >
      <NotificationsHeading
        handleOrderByChange={(orderBy) => setOrderBy(orderBy)}
      />
      {!loading && (
        <StyledContainer>
          {userNotificationsData?.length ? (
            <NotificationList userNotifications={userNotificationsData} />
          ) : (
            <NoNotification />
          )}
        </StyledContainer>
      )}
    </Layout>
  );
};

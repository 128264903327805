import { Layout } from 'components/Layout/Layout';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Map } from 'components/Map/Map';
import { CREATE_GAS_STATION_REPORT_NEW } from 'graphql/mutations/createGasStationReportNew';
import { useAccountType } from 'hooks/useAccountType';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ParkingZone } from 'types/ParkingZone';
import { SelectParkingZoneList } from 'components/SelectParkingZoneList/SelectParkingZoneList';
import { appContext } from 'views/App';

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-between;
`;

export const SelectParkingZone = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { windowHeight } = useWindowHeight();
  const { mutate } = useUploadMutation();
  const [selectedParking, setSelectedParking] = useState<ParkingZone | null>(
    null,
  );
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  useEffect(() => {
    if (!userData) return;
    setSelectedParking(userData?.parkingZones[0]);
  }, [userData]);

  const handleConfirm = () => {
    setIsLoading(true);
    const fileIds = reportDetails.files.map((file) => file.id);
    mutate(CREATE_GAS_STATION_REPORT_NEW, {
      variables: {
        ...reportDetails.carDetails,
        fullAddress: selectedParking.fullAddress,
        street: selectedParking.streetName,
        streetNumber: selectedParking.streetNumber,
        zipCode: selectedParking.zipCode,
        city: selectedParking.city,
        latitude: selectedParking.latitude,
        longitude: selectedParking.longitude,
        images: [...fileIds],
        bill: reportDetails?.bill,
        parkingZoneId: selectedParking?.id,
        inStore: reportDetails?.inStore,
      },
    })
      .then((result) => {
        setIsLoading(false);
        if (result.data) {
          setReportDetails({
            ...reportDetails,
            hash: result.data.createGasStationReportNew.hash,
            id: result.data.createGasStationReportNew.id,
          });
          if (isNotUserAccount) {
            setReportDetails({
              ...reportDetails,
              images: [],
              videos: [],
            });
            navigate(
              reportDetails.type === 1
                ? `/select-parking/${reportDetails.hash}`
                : '/success-order',
            );
          } else {
            navigate('/success-order');
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        throw new Error(error);
      });
  };

  return (
    <Layout
      isBackBtn
      isWithHeightAnimation={true}
      mode={'medium'}
      backgroundContent={
        <Map
          height={`calc(100vh - ${windowHeight - 295}px)`}
          position={
            selectedParking
              ? [selectedParking?.latitude, selectedParking?.longitude]
              : undefined
          }
        />
      }
    >
      <StyledContent>
        <div>
          <Heading color="var(--black)">{t('gasStationSelect')}</Heading>
          <SelectParkingZoneList
            selectedParking={selectedParking}
            handleSelectedParkingChange={(parking) =>
              setSelectedParking(parking)
            }
            parkingZones={userData?.parkingZones}
          />
        </div>
        <Button onBtnClick={handleConfirm} id="selectGasStationParkingZone">
          {t('confirm')}
        </Button>
      </StyledContent>
    </Layout>
  );
};

import { useMutation } from '@apollo/client';
import { Input } from 'components/Input/Input';
import { UPDATE_PARKING_ZONE_TICKET_CASHBACK } from 'graphql/mutations/updateParkingZoneTicketCashback';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { numberInputOnWheelPreventChange } from 'utils/numberInputOnWheelPreventChange';
import { appContext } from 'views/App';

interface TicketCashbackProps {
  isSaveButtonClecked?: boolean;
  selectedParkingId?: string | number;
  value?: string | number;
  isCashbackOn?: boolean;
}

export const TicketCashback = ({
  selectedParkingId,
  value,
  isSaveButtonClecked,
  isCashbackOn,
}: TicketCashbackProps) => {
  const { t } = useTranslation();
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;
  const methods = useForm();
  const { setValue, getFieldState, getValues } = methods;
  const [updateParkingZoneTicketCashback, { loading }] = useMutation(
    UPDATE_PARKING_ZONE_TICKET_CASHBACK,
    {
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setValue('ticketCashback', value);
  }, [value]);

  useEffect(() => {
    const { isDirty } = getFieldState('ticketCashback');
    if (isDirty) {
      updateParkingZoneTicketCashback({
        variables: {
          id: selectedParkingId,
          ticketCashbackValue: Number(getValues('ticketCashback')),
        },
      });
    }
  }, [isSaveButtonClecked]);

  if (!isCashbackOn) return;
  return (
    <FormProvider {...methods}>
      <form>
        <Input
          name="ticketCashback"
          label={t('cashback')}
          placeholder={t('cashbackPlaceholder')}
          type="number"
          mode="light"
          step="0.01"
          onWheel={numberInputOnWheelPreventChange}
        />
      </form>
    </FormProvider>
  );
};

import { ReportProps } from 'types/Report';
import { ReportDetail } from '../ReportDetail/ReportDetail';
import { Status } from 'components/Status/Status';
import { getReportStatusColor } from 'utils/getReportStatusColor';
import { useTranslation } from 'react-i18next';
import { useAccountType } from 'hooks/useAccountType';
import styled from 'styled-components/macro';

const StyledContent = styled.div`
  padding: 12px 0;
  justify-self: end;
`;

interface MainInformationProps {
  report?: ReportProps;
  eta?: string;
}

export const MainInformation = ({ report, eta }: MainInformationProps) => {
  const { t } = useTranslation();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  if (!report) return;
  return (
    <>
      <ReportDetail title="orderNumber" value={report?.id} />
      {report?.status ? (
        <StyledContent>
          <Status
            status={t(report?.status)}
            size="xs"
            color={getReportStatusColor(report?.status)}
            mode="report"
          />
        </StyledContent>
      ) : (
        <ReportDetail title="price" value={`${report?.price} CHF`} />
      )}
      {isNotUserAccount && (
        <>
          <ReportDetail
            title="customerName"
            value={`${report?.user?.firstName} ${report?.user?.lastName}`}
          />
          <ReportDetail
            title="customerNumber"
            value={`${report?.user?.phonePrefix} ${report?.user?.phoneNumber}`}
          />
        </>
      )}
      <ReportDetail title="licensePlate" value={report?.plate} />
      <ReportDetail
        title="dateAndTime"
        value={report?.createdAt.replace(' ', ', ')}
      />
      <ReportDetail
        title="address"
        value={`${report?.street} ${report?.streetNumber}`}
        additionalInfo={
          <>
            {report?.zipCode} {report?.city}
            <br />
            {report?.parkingZone?.name}
          </>
        }
      />
      {isUserAccount && (
        <ReportDetail
          title="customerName"
          value={`${report?.user?.firstName} ${report?.user?.lastName}`}
        />
      )}
      {report?.issueDate && report?.issueTime && (
        <ReportDetail
          title="billTime"
          value={`${report?.issueDate}, ${report?.issueTime}`}
        />
      )}
      {report?.policeParking?.addressFull && (
        <ReportDetail
          title="policyParkingAddress"
          value={`${report?.policeParking?.streetName} ${report?.policeParking?.streetNumber}`}
          additionalInfo={
            <>
              {report?.policeParking?.zipCode} {report?.policeParking?.city}
            </>
          }
        />
      )}
      {report?.status === 'accepted' && eta && (
        <ReportDetail title="ETA" value={eta} />
      )}
    </>
  );
};

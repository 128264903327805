import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Popup } from 'components/Popup/Popup';
import { MARK_GAS_STATION_REPORT_AS_PAID_ON_SPOT } from 'graphql/mutations/markGasStationReportAsPaidOnSpot';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { appContext } from 'views/App';

interface PaidOnSpotProps {
  reportPaymentStatus?: string;
  reportHash?: string;
  handlePayOnSpot: () => void;
}

export const PaidOnSpot = ({
  reportPaymentStatus,
  reportHash,
  handlePayOnSpot,
}: PaidOnSpotProps) => {
  const { t } = useTranslation();
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;
  const { type } = useParams();
  const isGasStationReport = type === 'station';
  const isReportNotPaid =
    reportPaymentStatus === 'notPaid' || reportPaymentStatus === 'open';
  const [isPayOnSpotSelected, setIsPayOnSpotSelected] =
    useState<boolean>(false);

  const [markGasStationReportAsPaidOnSpot, { loading }] = useMutation(
    MARK_GAS_STATION_REPORT_AS_PAID_ON_SPOT,
    {
      onCompleted: () => {
        setIsPayOnSpotSelected(false);
        handlePayOnSpot();
      },
      onError: (error) => {
        setIsPayOnSpotSelected(false);
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  const handlePayOnSpotConfirm = () => {
    markGasStationReportAsPaidOnSpot({ variables: { hash: reportHash } });
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <>
      {isGasStationReport && isReportNotPaid && (
        <Button
          mode="inverted"
          onBtnClick={() => setIsPayOnSpotSelected(true)}
          className="deleteReportButton"
        >
          {t('paidOnSpotButton')}
        </Button>
      )}
      <Popup
        isPopupOpened={isPayOnSpotSelected}
        type="action"
        onConfirm={handlePayOnSpotConfirm}
        onDecline={() => setIsPayOnSpotSelected(false)}
        content={t('confrimPayOnSpot')}
      />
    </>
  );
};

import { Layout } from 'components/Layout/Layout';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Map } from 'components/Map/Map';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Address, Position } from 'types/general';
import { ParkingZone } from 'types/ParkingZone';
import { getPosition } from 'utils/getPosition';
import { Popup } from 'components/Popup/Popup';
import { CloseReportButton } from 'components/CloseReportButton/CloseReportButton';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useAccountType } from 'hooks/useAccountType';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PROCESS_GAS_STATION_BILL } from 'graphql/mutations/processGasStationBill';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { CREATE_GAS_STATION_REPORT_NEW } from 'graphql/mutations/createGasStationReportNew';
import { USER_PARKING_ZONE_ALL } from 'graphql/queries/userParkingZoneAll';
import { ZURICH_POSITION } from 'data/position';
import { SelectParkingZoneList } from 'components/SelectParkingZoneList/SelectParkingZoneList';
import { appContext } from 'views/App';

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-between;
  gap: 20px;
`;

export const UserReportAddress = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const [position, setPosition] = useState<Position | undefined>(undefined);
  const [isAddressCorrect, setIsAddressCorrect] = useState<boolean>(true);
  const [isErrorPopupOpened, setIsErrorPopupOpened] = useState<boolean>(false);
  const [selectedParking, setSelectedParking] = useState<ParkingZone | null>(
    null,
  );
  const [parkings, setParkings] = useState<ParkingZone[] | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, setValue, getValues, watch } = methods;
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  const { windowHeight } = useWindowHeight();
  const { mutate } = useUploadMutation();
  const [userParkingZoneAll, { loading }] = useLazyQuery(
    USER_PARKING_ZONE_ALL,
    {
      onCompleted: (data) => {
        setParkings(data.userParkingZoneAll);
        setSelectedParking(data.userParkingZoneAll[0]);
      },
    },
  );

  useEffect(() => {
    userParkingZoneAll();
  }, []);

  const [processGasStationBill, { loading: processGasStationBillLoading }] =
    useMutation(PROCESS_GAS_STATION_BILL, {
      onCompleted: (data) => {
        if (!data) return;
      },
      onError: (error) => {
        setIsError(true);
        throw new Error(error.message);
      },
    });

  const onSubmit = () => {
    (document.activeElement as HTMLElement).blur();
    if (isAddressCorrect) {
      setIsLoading(true);
      if (reportDetails.type === 3) {
        let address;
        let addressData;
        if (selectedParking) {
          address = `${selectedParking.streetName} ${selectedParking.streetNumber}, ${selectedParking.zipCode} ${selectedParking.city}`;
          addressData = {
            fullAddress: address,
            street: selectedParking.streetName,
            streetNumber: selectedParking.streetNumber,
            zipCode: selectedParking.zipCode,
            city: selectedParking.city,
          };
        } else {
          address = `${getValues('reportStreet')} ${getValues(
            'reportStreetNumber',
          )}, ${getValues('reportZipCode')} ${getValues('reportCity')}`;
          addressData = {
            fullAddress: address,
            street: getValues('reportStreet'),
            streetNumber: getValues('reportStreetNumber'),
            zipCode: getValues('reportZipCode'),
            city: getValues('reportCity'),
          };
        }

        const fileIds = reportDetails.files.map((file) => file.id);
        mutate(CREATE_GAS_STATION_REPORT_NEW, {
          variables: {
            ...addressData,
            ...reportDetails.carDetails,
            latitude:
              position?.length > 0 ? position[0] : ZURICH_POSITION.latitude,
            longitude:
              position?.length > 0 ? position[1] : ZURICH_POSITION.longitude,
            images: [...fileIds],
            bill: reportDetails?.bill,
            parkingZoneId: selectedParking?.id,
            inStore: reportDetails?.inStore,
          },
        })
          .then((result) => {
            setIsLoading(false);
            if (result.data) {
              processGasStationBill({
                variables: { hash: result.data.createGasStationReportNew.hash },
              });
              setReportDetails({
                ...reportDetails,
                hash: result.data.createGasStationReportNew.hash,
                id: result.data.createGasStationReportNew.id,
              });
              if (isNotUserAccount) {
                setReportDetails({
                  ...reportDetails,
                  images: [],
                  videos: [],
                });
                navigate(
                  reportDetails.type === 1
                    ? `/select-parking/${reportDetails.hash}`
                    : '/success-order',
                );
              } else {
                navigate('/success-order');
              }
            }
          })
          .catch(() => {
            setIsLoading(false);
            setIsError(true);
          });
      } else {
        if (selectedParking) {
          const address = `${selectedParking.streetName} ${selectedParking.streetNumber}, ${selectedParking.zipCode} ${selectedParking.city}`;
          const addressData: Address = {
            fullAddress: address,
            street: selectedParking.streetName,
            streetNumber: selectedParking.streetNumber,
            zipCode: selectedParking.zipCode,
            city: selectedParking.city,
          };
          setReportDetails({
            ...reportDetails,
            parkingZoneId: selectedParking.id,
            address: {
              ...addressData,
              position,
            },
          });
        } else {
          const address = `${getValues('reportStreet')} ${getValues(
            'reportStreetNumber',
          )}, ${getValues('reportZipCode')} ${getValues('reportCity')}`;
          const addressData: Address = {
            fullAddress: address,
            street: getValues('reportStreet'),
            streetNumber: getValues('reportStreetNumber'),
            zipCode: getValues('reportZipCode'),
            city: getValues('reportCity'),
          };
          setReportDetails({
            ...reportDetails,
            address: {
              ...addressData,
              position,
            },
          });
        }
        setTimeout(() => {
          navigate(`/confirmation-pickup`);
        }, 500);
      }
    }
  };

  const handleGetPosition = async () => {
    const address = `${getValues('reportStreet')}+${getValues(
      'reportStreetNumber',
    )}+,+${getValues('reportZipCode')}+${getValues('reportCity')}`;
    const encodeAddress = encodeURI(address);
    const addressData = await getPosition(encodeAddress);
    if (addressData) {
      setIsAddressCorrect(true);
      setPosition([addressData.lat, addressData.lng]);
    } else {
      setIsAddressCorrect(false);
    }
  };

  const handleParkingSelect = (parking: ParkingZone) => {
    setSelectedParking(parking);
    setPosition([parking.latitude, parking.longitude]);
  };

  const handleErrorPopupClose = () => {
    setIsErrorPopupOpened(false);
    navigate('/');
  };

  useEffect(() => {
    const street = getValues('reportStreet');
    const streetNumber = getValues('reportStreetNumber');
    const zipCode = getValues('reportZipCode');
    const city = getValues('reportCity');
    if (street && streetNumber && zipCode && city) {
      handleGetPosition();
    }
  }, [
    watch('reportStreet'),
    watch('reportStreetNumber'),
    watch('reportZipCode'),
    watch('reportCity'),
  ]);

  useEffect(() => {
    if (!reportDetails) return;
    if (!reportDetails.address) return;
    setValue('reportStreet', reportDetails.address.street);
    setValue('reportStreetNumber', reportDetails.address.streetNumber);
    setValue('reportZipCode', reportDetails.address.zipCode);
    setValue('reportCity', reportDetails.address.city);

    setPosition([
      reportDetails.address.position?.length > 0
        ? reportDetails.address.position[0]
        : ZURICH_POSITION.latitude,
      reportDetails.address.position?.length > 0
        ? reportDetails.address.position[1]
        : ZURICH_POSITION.longitude,
    ]);
  }, [reportDetails]);
  useEffect(() => {
    setIsLoading(processGasStationBillLoading || loading);
  }, [processGasStationBillLoading, loading]);
  return (
    <Layout
      isBackBtn
      isWithHeightAnimation={true}
      mode={'medium'}
      backgroundContent={
        <Map
          height={`calc(100vh - ${windowHeight - 295}px)`}
          position={position && position}
        />
      }
      outsideElements={
        <div>
          <Popup
            isPopupOpened={isErrorPopupOpened}
            type="info"
            content={t('allowedCarPopup')}
            onDecline={handleErrorPopupClose}
            confirmText={t('back')}
          />
          <CloseReportButton
            onConfirmClick={() => {
              setReportDetails(null);
              navigate('/');
            }}
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id="reportAddressForm">
          {parkings?.length > 0 && (
            <div>
              <Heading color="var(--black)">{t('userOrderAddress')}</Heading>
              <SelectParkingZoneList
                parkingZones={parkings}
                handleSelectedParkingChange={(selectedParking) =>
                  handleParkingSelect(selectedParking)
                }
                selectedParking={selectedParking}
              />
            </div>
          )}
          <Button type="submit" disabled={!isAddressCorrect}>
            {t(reportDetails?.type === 3 ? 'confirm' : 'next')}
          </Button>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};

import styled from 'styled-components';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { ScannerIcon } from 'components/Icons/ScannerIcon/ScannerIcon';
import { CashbackIcon } from 'components/Icons/CashbackIcon/CashbackIcon';
import { EditGasStationIcon } from 'components/Icons/EditGasStationIcon/EditGasStationIcon';
import { useContext, useEffect, useRef, useState } from 'react';
import { UPDATE_USER_CASHBACK } from 'graphql/mutations/updateUserCashback';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_USER_GAS_STATION } from 'graphql/mutations/updateUserGasStation';
import { useParams } from 'react-router-dom';
import { USER } from 'graphql/queries/user';
import { BillingInformation as BillingInformationType } from 'types/general';
import { ParkingZone } from 'types/ParkingZone';
import { IconButton } from 'components/IconButton/IconButton';
import { UPDATE_USER_QR_CODE } from 'graphql/mutations/updateUserQRCode';
import { appContext } from 'views/App';
import { SwitchContainer } from './SwitchContainer/SwitchContainer';
import { BillingInformation } from './BillingInformation/BillingInformation';
import { IndividualPrices } from './IndividualPricesForm/IndividualPrices';

const StyledEditClientContainer = styled.div`
  .close {
    height: 0;
    top: 100%;
  }
`;

const StyledEditClientDetails = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: var(--white);
  z-index: 1003;
  border-radius: 32px 32px 0px 0px;
  padding: 4px 26px 26px;
  overflow: auto;
  transition: all 1s;
  .topButton {
    margin: 0 auto;
  }
`;

const StyledSwitchList = styled.ul`
  padding: 40px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledTopButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;
const StyledRectangle = styled.div`
  width: 44px;
  height: 4px;
  border-radius: 10px;
  background-color: var(--grey);
  margin: 0 auto;
`;

interface EditClientDetailsProps {
  isClose?: boolean;
  handleCloseStatusChange?: (isClose?: boolean) => void;
}
export const EditClientDetails = ({
  isClose,
  handleCloseStatusChange,
}: EditClientDetailsProps) => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;
  const { t } = useTranslation();
  const params = useParams();
  const [isCashbackOn, setIsCashbackOn] = useState<boolean>(false);
  const [isGasStationOn, setIsGasStationOn] = useState<boolean>(false);
  const [isQrCodeOn, setIsQrCodeOn] = useState<boolean>(false);
  const [clientParkingZones, setClientParkingZones] = useState<
    ParkingZone[] | []
  >([]);
  const [selectedParking, setSelectedPakring] = useState<ParkingZone | null>(
    null,
  );
  const [userBillingInformation, setUserBillingInformation] =
    useState<BillingInformationType | null>(null);

  const modalRef = useRef(null);
  const headerRef = useRef(null);

  const pullDownToCloseModal = ({ modalHeader, modal, closeModal }) => {
    const modalHeight = modal?.offsetHeight || 200;
    const modalHeaderHeight = modalHeader?.offsetHeight || 50;
    let touchDown = false;
    let firstTouchPos = 0;
    let firstTouchTime = 0;

    modalHeader.addEventListener('touchstart', (e) => {
      touchDown = true;
      firstTouchPos = e.touches[0].pageY;
      firstTouchTime = new Date().getTime();
    });

    modalHeader.addEventListener('touchmove', (e) => {
      e.preventDefault();
      if (touchDown) {
        const diff = e.touches[0].pageY - firstTouchPos;
        if (modal) modal.style.bottom = `-${diff}px`;
        if (diff > modalHeight - modalHeaderHeight) {
          closeModal();
          touchDown = false;
        }
      }
    });

    modalHeader.addEventListener('touchend', (e) => {
      if (!touchDown) return;
      const timeDiff = new Date().getTime() - firstTouchTime;
      const distanceDiff = e.changedTouches[0].pageY - firstTouchPos;

      if (
        (timeDiff * distanceDiff < 40000 &&
          timeDiff < 500 &&
          distanceDiff > 30) ||
        distanceDiff > modalHeight / 2 - modalHeight / 10
      ) {
        closeModal();
      }
      if (modal) modal.style.bottom = '0';

      touchDown = false;
    });
  };

  useEffect(() => {
    if (headerRef.current && modalRef.current) {
      pullDownToCloseModal({
        modalHeader: headerRef.current,
        modal: modalRef.current,
        closeModal: () => {
          handleCloseStatusChange(true);
        },
      });
    }
  }, [isClose]);

  const [user, { loading: userLoading }] = useLazyQuery(USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;
      setIsCashbackOn(data.user.cashback);
      setIsGasStationOn(data.user.gasStationUser);
      setClientParkingZones(data.user.parkingZones);
      setIsQrCodeOn(data.user.qrCode);
      setUserBillingInformation(
        data?.user?.billingInformations?.length > 0 &&
          data?.user?.billingInformations[0],
      );
      setSelectedPakring(
        data.user.parkingZones?.length > 0 ? data.user.parkingZones[0] : null,
      );
    },
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });
  const [updateUserCashback, { loading: updateUserCashbackLoading }] =
    useMutation(UPDATE_USER_CASHBACK, {
      onCompleted: (data) => {
        if (!data) return;
        setIsCashbackOn(data.updateUserCashback.cashback);
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    });

  const [updateUserGasStation, { loading: updateUserGasStationLoading }] =
    useMutation(UPDATE_USER_GAS_STATION, {
      onCompleted: (data) => {
        if (!data) return;
        setIsGasStationOn(data.updateUserGasStation.gasStationUser);
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    });

  const [updateUserQRCode, { loading: updateUserQRCodeLoading }] = useMutation(
    UPDATE_USER_QR_CODE,
    {
      onCompleted: (data) => {
        if (!data) return;
        setIsQrCodeOn(data.updateUserQRCode.qrCode);
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  useEffect(() => {
    user({
      variables: {
        id: params?.id,
      },
    });
  }, [params]);

  useEffect(() => {
    setIsLoading(
      userLoading ||
        updateUserCashbackLoading ||
        updateUserGasStationLoading ||
        updateUserQRCodeLoading,
    );
  }, [
    userLoading,
    updateUserCashbackLoading,
    updateUserGasStationLoading,
    updateUserQRCodeLoading,
  ]);

  const handleCashbackToggle = () => {
    updateUserCashback({
      variables: {
        user: params.id,
        cashback: !isCashbackOn,
      },
    });
  };

  const handleGasStationToggle = () => {
    updateUserGasStation({
      variables: {
        user: params.id,
        gasStationUser: !isGasStationOn,
      },
    });
  };
  const handleQRCodeToggle = () => {
    updateUserQRCode({
      variables: {
        user: params.id,
        qrCode: !isQrCodeOn,
      },
    });
  };
  const handleParkingChange = (parking: string) => {
    const newSelectedParking = clientParkingZones?.find(
      (item: ParkingZone) => item?.name === parking,
    );
    newSelectedParking && setSelectedPakring(newSelectedParking);
  };

  return (
    <StyledEditClientContainer ref={modalRef}>
      <StyledEditClientDetails className={isClose && 'close'}>
        <StyledTopButtonContainer ref={headerRef}>
          <IconButton onClick={() => handleCloseStatusChange(true)}>
            <StyledRectangle />
          </IconButton>
        </StyledTopButtonContainer>
        <Dropdown
          mode="full"
          options={clientParkingZones?.map((parkingZone) => parkingZone.name)}
          defaultOption={clientParkingZones && clientParkingZones[0]?.name}
          label={t('selectTheLocation')}
          handleSelectedOption={(option) => handleParkingChange(option)}
        />
        <StyledSwitchList>
          <SwitchContainer
            title="qrScanner"
            isChecked={isQrCodeOn}
            handleToggleClick={handleQRCodeToggle}
            icon={<ScannerIcon />}
          />
          <SwitchContainer
            title="fuelFraud"
            isChecked={isGasStationOn}
            handleToggleClick={handleGasStationToggle}
            icon={<EditGasStationIcon />}
          />
          <SwitchContainer
            title="cashback"
            isChecked={isCashbackOn}
            handleToggleClick={handleCashbackToggle}
            icon={<CashbackIcon />}
          />
        </StyledSwitchList>
        <IndividualPrices
          isCashbackOn={isCashbackOn}
          isGasStationOn={isGasStationOn}
          selectedParking={selectedParking}
        />
        <BillingInformation userBillingInformation={userBillingInformation} />
      </StyledEditClientDetails>
    </StyledEditClientContainer>
  );
};

import { Button } from 'components/Button/Button';
import { DisabledInput } from 'components/DisabledInput/DisabledInput';
import { Heading } from 'components/Heading/Heading';
import { CallOutgoing } from 'components/Icons/CallOutgoing/CallOutgoing';
import { MessageTextIcon } from 'components/Icons/MessageTextIcon/MessageTextIcon';
import { Popup } from 'components/Popup/Popup';
import { Layout } from 'components/Layout/Layout';
import { Map } from 'components/Map/Map';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import greenCarFull from 'assets/img/green-car-full.png';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Status } from 'components/Status/Status';
import { getReportStatusColor } from 'utils/getReportStatusColor';
import { useAccountType } from 'hooks/useAccountType';
import { CloseReportButton } from 'components/CloseReportButton/CloseReportButton';
import { CREATE_REPORT_LOG } from 'graphql/mutations/createReportLog';
import { useGetLocation } from 'hooks/useGetLocation';
import { REPORT } from 'graphql/queries/report';
import { getPosition } from 'utils/getPosition';
import car from 'assets/img/car-icon.png';
import { DRIVER_LOCALIZATION } from 'graphql/queries/driverLocalization';
import { UPDATE_DRIVER_LOCALIZATION_TIME } from 'data/updateDriverLocalizationTime';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { CREATE_REPORT_NEW } from 'graphql/mutations/createNewReport';
import { Textarea } from 'components/Textarea/Textarea';
import { ZURICH_POSITION } from 'data/position';
import { useUploadingFileProgress } from 'hooks/useUploadingFileProgress';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { appContext } from '../App';

const StyledInputContainer = styled.div`
  display: flex;
  max-width: 100%;
  position: relative;
  margin-bottom: 12px;
`;

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-between;
  padding: 65px 0 0;
  gap: 12px;
  .disabledInput {
    margin-right: 6px;
  }
  .button {
    :first-child {
      margin-right: 8px;
    }
    :last-child {
      margin-left: 8px;
    }
  }
  .notesContent {
    height: max-content;
  }
`;

const StyledIconsContainer = styled.div`
  display: flex;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
`;
const StyledLink = styled.a`
  text-decoration: none;
  background: transparent;
  border: none;
  padding: 0;
  background-color: var(--green-more-opacity);
  border: 1px solid var(--green-opacity);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-right: 12px;
  }
`;

const StyledStatusContainer = styled.div`
  position: relative;
  width: 100%;
  margin-left: 6px;
  span {
    font-size: var(--text-xs);
  }
  .confirmStatus {
    position: absolute;
    left: 12px;
    top: 26px;
    min-width: 60px;
    width: auto;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }
`;
const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 15px;
`;

const icon: google.maps.Icon = {
  url: car,
  size: { equals: () => false, width: 52, height: 52 },
};

export const ConfirmationPickup = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const [isCancelPopupOpened, setIsCancelPopupOpened] =
    useState<boolean>(false);
  const [driverLocalizationDetails, setDriverLocalizationDetails] =
    useState<(string | number)[]>();
  const { t } = useTranslation();
  const methods = useForm();
  const { setValue } = methods;
  const navigate = useNavigate();
  const { position, getLocation } = useGetLocation();
  const params = useParams();
  const location = useLocation();
  const isReportPage = location.pathname.includes('active-report');
  const { mutate } = useUploadMutation();
  const [isZipCodeInvalid, setIsZipCodeinvalid] = useState<boolean>(false);
  const [isErrorPopupOpened, setIsErrorPopupOpened] = useState<boolean>(false);

  const { uploadedFilesCount } = useUploadingFileProgress();
  const [isReportCreated, setIsReportCreated] = useState<boolean>(false);
  const [isUploadingFilesInProgress, setIsUploadingFilesInProgress] =
    useState<boolean>(true);
  useEffect(() => {
    const addedFilesCount = Number(sessionStorage.getItem('addedFilesCount'));
    if (addedFilesCount) {
      if (uploadedFilesCount < addedFilesCount) {
        setIsUploadingFilesInProgress(true);
      } else {
        setIsUploadingFilesInProgress(false);
      }
    }
  }, [uploadedFilesCount]);

  const [report, { data: reportData, loading: reportLoading }] =
    useLazyQuery(REPORT);

  useEffect(() => {
    if (!reportDetails) return;
    setTimeout(() => {
      const textares = document.getElementById('notes');
      if (textares) {
        document.getElementById('notes').style.height =
          document.getElementById('notes').scrollHeight + 'px';
      }
    }, 500);
  }, [reportDetails]);

  const [createReportLog, { loading: createReportLogLoading }] = useMutation(
    CREATE_REPORT_LOG,
    {
      onCompleted: () => {
        if (isDriverAccount) {
          sessionStorage.removeItem('qrCodeId');
          setReportDetails({
            ...reportData,
            images: [],
            videos: [],
          });
          if (isUploadingFilesInProgress && reportDetails.type === 1) {
            setIsReportCreated(true);
          } else {
            navigate(
              reportDetails.type === 1
                ? `/select-parking/${reportDetails.hash}`
                : '/success-order',
            );
          }
        } else {
          navigate('/success-order');
        }
      },
    },
  );

  useEffect(() => {
    if (
      !isUploadingFilesInProgress &&
      reportDetails.type === 1 &&
      isReportCreated
    ) {
      navigate(
        reportDetails.type === 1
          ? `/select-parking/${reportDetails.hash}`
          : '/success-order',
      );
    }
  }, [isUploadingFilesInProgress, isReportCreated]);

  const [driverLocalization, { refetch: refetchDriverLocalization }] =
    useLazyQuery(DRIVER_LOCALIZATION, {
      onCompleted: (data) => {
        if (!data) return;
        setDriverLocalizationDetails([
          data.driverLocalization[0]?.latitude,
          data.driverLocalization[0]?.longitude,
        ]);
      },
    });

  const { isThisAccountType } = useAccountType('user');
  const { isThisAccountType: isDriverAccount } = useAccountType('driver');
  const [isNotUserAccount, setIsNotUserAccount] = useState(false);

  useEffect(() => {
    if (params?.hash) {
      report({ variables: { hash: params.hash } });
      if (isReportPage) {
        driverLocalization({ variables: { hash: params?.hash } });
      }
    }
  }, [params]);

  useEffect(() => {
    if (!reportData) return;

    const distance = reportData?.report.driverMap?.distance;
    const time = Math.floor(distance / 0.66);
    const eta = time <= 1 ? `1 ${t('minute')}` : `${time} ${t('minutes')}`;
    const fullAddress = `${reportData.report.street} ${reportData.report.streetNumber}, ${reportData.report.zipCode} ${reportData.report.city}`;
    getPosition(fullAddress).then((result) => {
      const reportDetails = {
        id: reportData.report.id,
        hash: reportData.report.hash,
        driverName: reportData?.report?.driverName,
        carDetails: {
          plate: reportData.report.plate,
          carName: reportData.report.carName,
        },
        type: reportData.report.type,
        distance: 0,
        address: {
          street: reportData.report.street,
          streetNumber: reportData.report.streetNumber,
          zipCode: reportData.report.zipCode,
          city: reportData.report.city,
          fullAddress: `${reportData.report.street} ${reportData.report.streetNumber}, ${reportData.report.zipCode} ${reportData.report.city}`,
          position: [result?.lat || 0, result?.lng || 0],
          additionalPossition: (reportData.report.status === 'accepted' && [
            reportData.report.logs[0]?.latitude || 0,
            reportData.report.logs[0]?.longitude || 0,
          ]) || [0, 0],

          additionalPossitionTitle:
            (reportData.report.status === 'accepted' &&
              'pinTowingTruckLocation') ||
            (reportData.report.status === 'inProgress' &&
              'pinParkingSpaceLocation'),
        },
        eta,
        status: reportData.report.status,
      };
      setDriverLocalizationDetails([
        reportData.report.logs[0]?.latitude,
        reportData.report.logs[0]?.longitude,
      ]);
      if (reportData.report.status === 'accepted') {
        setValue('orderStatus', reportDetails.eta);
      }
      setReportDetails(reportDetails);
    });
  }, [reportData]);

  useEffect(() => {
    if (!reportDetails) return;
    if (
      !reportDetails.address &&
      reportDetails.carDetails &&
      reportDetails.type
    )
      return;
    setValue(
      'location',
      `${reportDetails.address?.street} ${reportDetails.address?.streetNumber}`,
    );
    setValue('license', reportDetails.carDetails?.plate);
    reportDetails?.notes && setValue('notes', reportDetails?.notes);

    setIsNotUserAccount(
      typeof isThisAccountType !== 'undefined' && isThisAccountType === false
        ? true
        : false,
    );

    if (reportDetails.type === 1) {
      setValue(
        'driverName',
        `${reportDetails?.driver?.firstName} ${reportDetails?.driver?.lastName}`,
      );
      if (isNotUserAccount) {
        setValue('orderStatus', 'Vor Ort');
      } else if (!isReportPage) {
        setValue('orderStatus', '');
      } else if (isReportPage && reportDetails.status != 'accepted') {
        setValue('orderStatus', '');
      }
    }
  }, [reportDetails, isNotUserAccount, isReportPage]);

  const handleConfirmClick = () => {
    setIsLoading(true);
    const fileIds = reportDetails.files?.map((file) => file.id);
    if (reportDetails.type === 3) {
      console.log('test');
    } else {
      mutate(CREATE_REPORT_NEW, {
        variables: {
          ...reportDetails.address,
          ...reportDetails.carDetails,
          notes: reportDetails?.notes,
          qrCodeId: reportDetails?.qrCodeId,
          latitude: position?.latitude || ZURICH_POSITION.latitude,
          longitude: position?.longitude || ZURICH_POSITION.longitude,
          type: reportDetails.type,
          parkingZoneId: reportDetails?.parkingZoneId,
          images: [...fileIds],
        },
      })
        .then((result) => {
          setIsLoading(false);
          if (result.data) {
            setReportDetails({
              ...reportDetails,
              hash: result.data.createReportNew.hash,
              id: result.data.createReportNew.id,
            });
            createReportLog({
              variables: {
                id: result.data.createReportNew.id,
                information: 'Report User Start',
                latitude: position?.latitude || ZURICH_POSITION.latitude,
                longitude: position?.longitude || ZURICH_POSITION.longitude,
              },
            });
          }
        })
        .catch((value) => {
          setIsLoading(false);
          if (value?.message === 'Zip code not allowed') {
            setIsZipCodeinvalid(true);
          } else if (value?.message === 'This car is allowed to park here') {
            setIsErrorPopupOpened(true);
          } else {
            setIsError(true);
          }
        });
    }
  };

  const handleErrorPopupClose = () => {
    setIsErrorPopupOpened(false);
    navigate('/');
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    setIsLoading(createReportLogLoading || reportLoading);
  }, [createReportLogLoading, reportLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isReportPage) {
        refetchDriverLocalization({ hash: params?.hash }).then(({ data }) => {
          if (!data) return;
          if (data.driverLocalization[0]) {
            setDriverLocalizationDetails([
              data.driverLocalization[0]?.latitude,
              data.driverLocalization[0]?.longitude,
            ]);
          }
        });
      }
    }, UPDATE_DRIVER_LOCALIZATION_TIME);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Layout
        isBackBtn
        mode="medium"
        backgroundContent={
          <Map
            height={`calc(100vh - (100vh - 295px))`}
            position={reportDetails?.address?.position || undefined}
            additionalPossition={driverLocalizationDetails || undefined}
            additionalPossitionMarkerIcon={icon}
            zoom={14}
          />
        }
        topContent={<img src={greenCarFull} height={141} />}
        topContentPosition="center"
        topContentSize="m"
        backBtnLink={!isReportPage && `/add-report/${reportDetails?.type}`}
        outsideElements={
          <div>
            <Popup
              isPopupOpened={isCancelPopupOpened}
              type="action"
              content={t('cancelOrderAlert')}
              onDecline={() => setIsCancelPopupOpened(false)}
              declineText="cancelButton"
              onConfirm={() => navigate('/')}
              confirmText="confirmNext"
            />
            {!params?.hash && (
              <CloseReportButton
                onConfirmClick={() => {
                  setReportDetails(null);
                  navigate('/');
                }}
              />
            )}
            <Popup
              isPopupOpened={isErrorPopupOpened}
              type="info"
              content={t('allowedCarPopup')}
              onDecline={handleErrorPopupClose}
              confirmText={t('back')}
            />

            <Popup
              isPopupOpened={isZipCodeInvalid}
              content={t('invalidZipCodePopup')}
              type="info"
              onDecline={() => navigate('/')}
              confirmText="cancel"
            />
            <Popup
              isCloseButton={false}
              isPopupOpened={isUploadingFilesInProgress && isReportCreated}
              type={'info'}
              customerContent={
                <Paragraph
                  color="var(--black)"
                  textAlign="center"
                  dimension="l"
                >
                  {t('loadingMediaPopup')}
                </Paragraph>
              }
              isWithOutButton
            />
          </div>
        }
      >
        {reportDetails ? (
          <FormProvider {...methods}>
            <StyledForm>
              <div>
                <StyledTitle>
                  <Heading color="var(--black)" isWithPaddingBottom={false}>
                    {t(
                      reportDetails?.type === 1
                        ? 'badParkedCarConfirmationPickup'
                        : 'badParkedCarTicketConfirmationPickup',
                    )}
                  </Heading>
                </StyledTitle>
                <DisabledInput
                  name="location"
                  mode="light"
                  label={t('pickUpLocation')}
                  bottomMarting="12"
                />
                <StyledInputContainer>
                  <DisabledInput
                    name="license"
                    mode="light"
                    label={t('licensePlate')}
                    inputClassName="disabledInput"
                  />
                  <StyledStatusContainer>
                    <DisabledInput
                      name={
                        reportDetails?.type === 1 ? 'orderStatus' : 'orderID'
                      }
                      mode="light"
                      label={
                        reportDetails?.type === 1
                          ? reportDetails?.status === 'accepted'
                            ? t('ETA')
                            : t('orderStatus')
                          : t('orderID')
                      }
                    />
                    {reportDetails?.status &&
                      reportDetails?.status !== 'accepted' && (
                        <Status
                          status={t(reportDetails?.status || 'new')}
                          color={getReportStatusColor(
                            reportDetails?.status || 'new',
                          )}
                          mode="report"
                          className="confirmStatus"
                          size="m"
                        />
                      )}
                  </StyledStatusContainer>
                </StyledInputContainer>{' '}
                {reportDetails?.notes && isNotUserAccount && (
                  <Textarea
                    name="notes"
                    mode="light"
                    label={t('notes')}
                    disabled
                    contentClassName={'notesContent'}
                  />
                )}
                {reportDetails?.type === 1 &&
                  reportDetails?.driver?.phonePrefix && (
                    <DisabledInput
                      name={'driverName'}
                      mode="light"
                      label={t('towingDriverName')}
                      rightContent={
                        <StyledIconsContainer>
                          <StyledLink
                            href={`tel:${reportDetails?.driver?.phonePrefix}${reportDetails?.driver?.phoneNumber}`}
                          >
                            <CallOutgoing />
                          </StyledLink>
                          <StyledLink
                            href={`whatsapp://send?phone=${reportDetails?.driver?.phonePrefix.replaceAll(
                              ' ',
                              '',
                            )}${reportDetails?.driver?.phoneNumber.replaceAll(
                              ' ',
                              '',
                            )}`}
                          >
                            <MessageTextIcon />
                          </StyledLink>
                        </StyledIconsContainer>
                      }
                      bottomMarting="16"
                    />
                  )}
              </div>
              {!params?.hash && (
                <StyledButtonsContainer>
                  <Button
                    mode="inverted"
                    onBtnClick={() => setIsCancelPopupOpened(true)}
                    className="button"
                    id="cancelReport"
                  >
                    {t('cancelButton')}
                  </Button>
                  <Button
                    onBtnClick={handleConfirmClick}
                    className="button"
                    id="confirmReport"
                  >
                    {t('confirm')}
                  </Button>
                </StyledButtonsContainer>
              )}
            </StyledForm>
          </FormProvider>
        ) : (
          <></>
        )}
      </Layout>
    </>
  );
};

import { useMutation } from '@apollo/client';
import { Input } from 'components/Input/Input';
import { Button } from 'components/Button/Button';
import { ADD_VEHICLE_TO_PARKING } from 'graphql/mutations/addVehicleToParking';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import companyLogo from 'assets/img/logo.svg';
import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { AppSettings } from 'types/general';
import { plateValidation } from 'data/regexPatterns';
import { appContext } from 'views/App';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 700px;
  margin: 0 auto;
  button {
    text-align: center;
    padding: 18px 0;
    border-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};
  }
`;
const StyledImage = styled.img`
  width: 100%;
  max-width: 550px;
  max-height: 200px;
  object-fit: contain;
  height: auto;
  margin: 0 auto;
`;
interface ActivateParkingFormProps {
  parkingUuid: string;
  appSettings: AppSettings;
  handlePopupOpened: (isPopupOpened: boolean) => void;
}
export const ActivateParkingForm = ({
  parkingUuid,
  appSettings,
  handlePopupOpened,
}: ActivateParkingFormProps) => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [appSettingsData, setAppSettingsData] = useState<
    AppSettings | undefined
  >(undefined);
  const methods = useForm();
  const { handleSubmit, getValues, watch, setValue } = methods;
  const [addVehicleToParking, { loading }] = useMutation(
    ADD_VEHICLE_TO_PARKING,
    {
      onCompleted: () => handlePopupOpened(true),
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setAppSettingsData(appSettings);
  }, [appSettings]);

  const onSubmit = () => {
    addVehicleToParking({
      variables: {
        parkingZoneUuid: parkingUuid,
        licensePlate: getValues('activateParkingLicense'),
      },
    });
  };

  useEffect(() => {
    setValue(
      'activateParkingLicense',
      getValues('activateParkingLicense')?.toUpperCase(),
    );
  }, [watch('activateParkingLicense')]);
  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledImage
          src={appSettingsData ? appSettingsData?.logo : companyLogo}
          alt="Company Logo"
        />
        <Heading color="var(--black-text)" textAlign="center" dimension="l">
          {t('activateParkingTitle')}
        </Heading>
        <Paragraph
          color="var(--black-text)"
          dimension="xl"
          weight="bold"
          textAlign="center"
        >
          {t('license')}:
        </Paragraph>
        <Input
          name={'activateParkingLicense'}
          isRequired
          type="text"
          placeholder={t('activateParkingPlaceholder')}
          validation={plateValidation}
          validationErrorMessage={t('plateValidation')}
          requiredErrorMessage={t('requiredError')}
        />
        <Button
          mode="inverted"
          type="submit"
          className="parkingActivateConfirmButton"
        >
          {t('confirmActivateParking')}
        </Button>
      </StyledForm>
    </FormProvider>
  );
};

import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Popup } from 'components/Popup/Popup';
import { DELETE_REPORT_NEW } from 'graphql/mutations/deleteReportNew';
import { useAccountType } from 'hooks/useAccountType';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { appContext } from 'views/App';

interface DeleteReportProps {
  reportStatus?: string;
  reportHash?: string;
}

export const DeleteReport = ({
  reportStatus,
  reportHash,
}: DeleteReportProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;
  const { type } = useParams();
  const isNotGasStationReport = type != 'station';
  const { isThisAccountType: isDriverAccount } = useAccountType('driver');
  const isNotDriverAccount = !isDriverAccount;
  const [isConfirmDeletePopupOpened, setIsConfirmDeletePopupOpened] =
    useState<boolean>(false);

  const [removeReport, { loading }] = useMutation(DELETE_REPORT_NEW, {
    onCompleted: () => {
      setIsLoading(false);
      navigate(-1);
    },
    onError: (error) => {
      setIsConfirmDeletePopupOpened(false);
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const handleDelete = () => {
    setIsConfirmDeletePopupOpened(true);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <>
      {isNotDriverAccount &&
        reportStatus != 'cancelled' &&
        isNotGasStationReport && (
          <Button
            mode="inverted"
            onBtnClick={handleDelete}
            className="deleteReportButton"
          >
            {t('deleteReport')}
          </Button>
        )}
      <Popup
        isPopupOpened={isConfirmDeletePopupOpened}
        onConfirm={() => {
          removeReport({ variables: { hash: reportHash } });
          setIsConfirmDeletePopupOpened(false);
        }}
        onDecline={() => setIsConfirmDeletePopupOpened(false)}
        content={t('confirmReportDelete')}
        type="action"
        declineText="no"
        confirmText="yes"
        confirmButtonId="deleteReportButton"
      />
    </>
  );
};

import { useLazyQuery } from '@apollo/client';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { USER } from 'graphql/queries/user';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User } from 'types/User';
import { appContext } from 'views/App';

export const ClientInformation = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const params = useParams();
  const [userData, setUserData] = useState<User | null>(null);
  const [user, { loading: userLoading }] = useLazyQuery(USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;
      setUserData(data.user);
    },
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });
  useEffect(() => {
    user({
      variables: {
        id: params?.id,
      },
    });
  }, [params]);
  useEffect(() => {
    setIsLoading(userLoading);
  }, [userLoading]);
  return (
    <>
      <Paragraph dimension="m" weight="bold" color="var(--white-always)">
        {userData?.firstName} {userData?.lastName}
      </Paragraph>
      {userData?.companyName && (
        <Paragraph dimension="m" weight="bold" color="var(--white-always)">
          {userData?.companyName}
        </Paragraph>
      )}
    </>
  );
};

import { Accordion } from 'components/Accordion/Accordion';
import { Popup } from 'components/Popup/Popup';
import { Layout } from 'components/Layout/Layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { LanguageButtonContainer } from './LanguageButtonContainer/LanguageButtonContainer';

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Language = () => {
  const { t, i18n } = useTranslation();
  const elements = ['de', 'en'];
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  useEffect(() => {
    const lang = localStorage.getItem('language');
    if (lang === 'de') {
      setSelectedLanguage(['de']);
    } else {
      setSelectedLanguage(['en']);
    }
  }, []);

  const handleLanguageChange = () => {
    i18n.changeLanguage(selectedLanguage[0]);
    localStorage.setItem('language', selectedLanguage[0]);
    setIsPopupOpened(true);
  };
  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('language')}
      outsideElements={
        <Popup
          isPopupOpened={isPopupOpened}
          onDecline={() => setIsPopupOpened(false)}
          content={t('confirmChangeSave')}
          confirmText="ok"
          type="info"
        />
      }
    >
      <StyledContainer>
        <Accordion
          title={t('defaultLanguage')}
          isAccordionOpened={true}
          elements={elements}
          onClick={(element) => setSelectedLanguage([element])}
          selectedElements={selectedLanguage}
          isWithSelectIcon={true}
        ></Accordion>
        <LanguageButtonContainer handleLanguageChange={handleLanguageChange} />
      </StyledContainer>
    </Layout>
  );
};

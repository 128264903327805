import { ButtonHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { CircleIcon } from '../Icons/CircleIcon/CircleIcon';

interface SelectButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  content: ReactNode;
  isSelected: boolean;
}

const StyledContainer = styled.button`
  background-color: var(--grey-opacity);
  border: none;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 12px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .elementText {
    margin-right: 5px;
  }
`;

export const SelectButton = ({
  content,
  isSelected = false,
  ...props
}: SelectButtonProps) => {
  return (
    <StyledContainer type="button" {...props}>
      {content}{' '}
      <CircleIcon fill={isSelected ? 'var(--black)' : 'var(--white)'} />
    </StyledContainer>
  );
};

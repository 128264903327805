import { gql } from '@apollo/client';

export const PARKING_OWNERS = gql`
  query ParkingOwners(
    $first: Int!
    $page: Int
    $cashback: Boolean
    $filter: String
  ) {
    parkingOwners(
      orderBy: [{ column: FIRST_NAME, order: ASC }]
      first: $first
      page: $page
      cashback: $cashback
      filter: $filter
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        firstName: first_name
        lastName: last_name
        companyName: company_name
        company_address
        email
        email_verified_at
        verified
        rank
        balance
        phonePrefix: phone_prefix
        phoneNumber: phone_number
        ref_code
        avatar
        created_at
        updated_at
        cashback
        licenses {
          id
          beneficiary
          car_name
          plate
          created_at
          updated_at
        }
        parkingZones {
          id
          type
          name
          address_full
          streetName: street_name
          streetNumber: street_number
          zipCode: zip_code
          city
          latitude
          longitude
          signature
          created_at
          updated_at
        }
        reports {
          id
          type
          hash
          plate
          car_name
          status
          payment_status
          phone_prefix
          phone_number
          address_full
          street
          street_number
          zip_code
          city
          latitude
          longitude
          signature
          created_at
          updated_at
        }
        userRewards {
          id
          amount
          status
          created_at
          updated_at
        }

        parent {
          id
          first_name
          last_name
          company_name
          company_address
          email
          email_verified_at
          verified
          rank
          balance
          phone_prefix
          phone_number
          ref_code
          avatar
          created_at
          updated_at
          cashback
        }
        referredUsers {
          id
          first_name
          last_name
          company_name
          company_address
          email
          email_verified_at
          verified
          rank
          balance
          phone_prefix
          phone_number
          ref_code
          avatar
          created_at
          updated_at
          cashback
        }
        driverReports {
          id
          created_at
          updated_at
          distance
          status
        }
        reportNotifications {
          id
          text
          text_en
          displayed
          created_at
          updated_at
        }
        localizationsLog {
          id
          latitude
          longitude
          created_at
          updated_at
        }
      }
    }
  }
`;

import { ReactNode, useContext } from 'react';
import styled from 'styled-components/macro';
import { appContext } from 'views/App';

interface StyledCardProps {
  topContainerColor?: string;
  children: ReactNode;
  contentAlign?: 'flex-start' | 'center';
  size?: 's' | 'm';
  mode?: boolean;
}

const CardContainer = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
`;
const StyledCard = styled.div<StyledCardProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: ${({ contentAlign }) => contentAlign};
  align-items: center;
  padding: 0px;
  background-color: var(--card-background);
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  height: ${({ size }) => (size === 'm' ? '185px' : '135px')};
  margin-top: ${({ size }) => (size === 'm' ? '40px' : '0')};
  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    width: calc(100% - 40px);
    height: 15px;
    border-radius: 20px 20px 0px 0px;
    background: ${({ topContainerColor }) => topContainerColor};
  }
`;

export const Card = ({
  children,
  topContainerColor,
  contentAlign = 'flex-start',
  size = 'm',
}: StyledCardProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  return (
    <CardContainer>
      <StyledCard
        topContainerColor={
          topContainerColor
            ? topContainerColor
            : userData?.appSettings?.primary_color
            ? userData?.appSettings?.primary_color
            : 'var(--green)'
        }
        contentAlign={contentAlign}
        size={size}
      >
        {children}
      </StyledCard>
    </CardContainer>
  );
};

export const qrCodeTickets = {
  cardriverDetailsTitle: 'Fahrzeugführer/in bekannt geben',
  carDriverDetailsDescription:
    ' Wenn Sie für die Umtriebsentschädigung nicht verantwortlich sind, können Sie die Umtriebsentschädigung innert 20 Tagen auf die Fahrzeugführerin oder den Fahrzeugführer umschrieben lassen.',
  firstName: 'Nachname',
  lastName: 'Vorname',
  streetName: 'Strasse',
  streetNumber: 'Hausnummer',
  zipCode: 'PLZ',
  city: 'Ort',
  email: 'Email',
  carDriverInfo: 'Alle mit Stern gekennzeichneten Felder sind auszufüllen.',
  legalInformation: 'Weitere und rechtliche Informationen',
  legalInformationElement:
    'Sollte Ihr Kontrollschild gesperrt sein, haben Sie 24 Stunden Zeit, uns Ihre Halterangaben über das Online-Formular oder per E-Mail an info@falsch-parker.ch mitzuteilen. Andernfalls können zusätzliche Kosten für Sie entstehen.\n\nFalschparken auf Privatgrund (Art. 641 + 926 + 927 + 928 ZGB) aus Abtretung gem. Art. 164 ff. OR',
  next: 'Weiter',
  back: 'Zurück',
  chedckDetailsTitle: 'Angaben prüfen und senden',
  checkDetailsDescription: 'Bitte überprüfen Sie die Angaben.',
  ticketNumberQR: 'Umtriebsentschädigungs-Nr',
  plate: 'Kontrollschild',
  countryCode: 'Landeskennzeichen',
  notes: 'Notiz',
  fineAmount: 'Bussenbetrag',
  zurichCityPolice: 'Stadtpolizei Zürich',
  placeAndDateOfTheOffense: 'Ort und Datum der Übertretung',
  streetAndStreetNumber: 'Strasse, Hausnummer',
  zipCodeAndCity: 'PLZ, Ort',
  timeAndDate: 'Datum, Zeit',
  personalDetailsOfTheDriver: 'Personalien Fahrzeugführer/in',
  name: 'Nachname / Vorname',
  addressSuffix: 'Adresszusatz',
  checkDetailsInfo:
    'Hiermit bestätige ich, dass meine Angaben korrekt sind. Wer wider besseres Wissen eine nichtschuldige Person bei der Behörde einer strafbaren Handlung beschuldigt, in der Absicht, eine Strafverfolgung gegen sie herbeizuführen, macht sich strafbar (Art. 303 Strafgesetzbuch).*',
  send: 'Senden',
  failedViewTitle: 'Zahlung fehlgeschlagen :(',
  failedViewSubtitle: 'Bitte versuchen Sie es erneut oder kontaktieren Sie uns',
  failedViewButton: 'Versuchen Sie es erneut',
  objectionRaiseTitle: 'Einwand erheben',
  objectionRaiseSubtitle:
    'Sind Sie der Meinung, dass die Umtriebsentschädigung ungerechtfertig oder falsch ist?',
  objectionRaiseDescription:
    'Falls Ihr Einwand im Zusammenhang mit der Nutzung einer Parking-App steht, bitten wir Sie den Beleg des betreffenden Parkvorgangs des App-Anbieters ihrem Einwand beizulegen, resp. als Datei hochzuladen. Diese benötigen wir in jedem Fall um Ihren Einwand zu prüfen.',
  note: 'Ihr Einwand',
  notePlaveholder: 'Bitte begründen Sie Ihren Einwand (maximal 2000 Zeichen)',
  objectionRaiseUpload: 'Eine Datei anfügen',
  addFile: 'Datei hinzufügen',
  addFileDesc: '(Max. 5 * 10MB, erlaubte Formate: pdf, png, jpg, bmp)',
  successPaymentTitle: 'Ihre Zahlung war Erfolgreich!',
  successPaymentSubtitle: 'Besten Dank für Ihre Zahlung',
  paymentInfoTitle: 'Bezahlen / Zahlungsinformationen',
  paymentInfoSubtitle:
    ' Sie haben folgende Möglichkeiten, die Umtriebsentschädigung zu bezahlen:',
  paymentMethodTicket: 'Kreditkarte, Postfinance Card, Twint oder E-Banking',
  pay: 'Bezahlen',
  eBanking: 'E-Banking',
  bankAccountNumber: 'Kontonummer',
  yourReferenceNo: 'Ihre Referenz-Nr.',
  amount: 'Betrag',
  inFavorOf: 'Zugunsten von',
  paymentSlip: 'Einzahlungsschein / Zahlschein',
  paymentSlipDesc:
    'Wenn die Umtriebsentschädigung 2 Tage nach dem Parkvergehen nicht bezahlt ist, senden wir der verantwortlichen Person automatisch eine Umtriebsentschädigung mit Einzahlungsschein zu.',
  acceptAGB: 'Ich akzeptiere die AGB`s',
  congratulations: 'Glückwünsch!',
  successCarOwnerAddingSubtitle:
    'Die Informationen zum Fahrzeugführer wurden übermittelt',
  successObjectionAddingSubtitle: 'Ihr Einwand wurde erfolgreich übermittelt',
  ticketDetailsTitle: 'Details zu der Umtriebsentschädigung',
  ticketDetaildNumber: 'Umtriebsentschädigung-Nr',
  parkingOffendersEmployees: 'Falschparker Mitarbeiter',
  placeAndDateOfTheParkingViolation: 'Ort und Datum des Parkverstosses',
  objection: 'Einwand',
  payNow: 'Jetzt bezahlen',
  ticketTitle: 'Bitte geben Sie das \n Kontrollschild ein',
  or: 'Und',
  plateNumber: 'Kontrollschild',
  noTicketTitle: 'Keine Umtriebsentschädigung gefunden',
  noTicketDesc:
    'Leider haben wir mit den eingegebenen Informationen keine Umtriebsentschädigung gefunden. Bitte Scannen Sie den QR-Code nochmals und überprüfen Sie die die UE-Nr.',
};

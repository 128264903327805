import { IconButton, IconButtonProps } from 'components/IconButton/IconButton';
import { NotificationsIcon } from 'components/Icons/NotificationsIcon/NotificationsIcon';
import { PhoneIcon } from 'components/Icons/PhoneIcon/PhoneIcon';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { useAccountType } from 'hooks/useAccountType';
import { useUserNewNotifications } from 'hooks/useUserNewNotifications';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { appContext } from 'views/App';

const StyledIconButton = ({
  className,
  children,
  onClick,
}: IconButtonProps) => (
  <IconButton className={className} onClick={onClick}>
    {children}
  </IconButton>
);

const OverstyledIconButton = styled(StyledIconButton)`
  position: absolute;
  right: 30px;
`;
const PhoneLink = styled.a`
  position: absolute;
  right: 60px;
`;

export const DashboardUserBar = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const navigate = useNavigate();
  const { isNewNotifications } = useUserNewNotifications();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  return (
    <div>
      <OverstyledIconButton onClick={() => navigate('/notifications')}>
        <NotificationsIcon
          isNewNotifications={isNewNotifications}
          stroke={userData?.appSettings?.primary_color}
        />
      </OverstyledIconButton>
      {isUserAccount && (
        <PhoneLink href="tel:+41445014270">
          <PhoneIcon stroke={userData?.appSettings?.primary_color} />
        </PhoneLink>
      )}
      <UserInfo
        layout="horizontal"
        onUserInfoClick={() => navigate('/edit-profile')}
      />
    </div>
  );
};

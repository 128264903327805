import { Layout } from 'components/Layout/Layout';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useMutation } from '@apollo/client';
import { CREATE_USER_BILLING_INFORMATION } from 'graphql/mutations/createUserBillingInformation';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UPDATE_USER_BILLING_INFORMATION } from 'graphql/mutations/updateUserBillingInformation';
import { useParams } from 'react-router-dom';
import { Popup } from 'components/Popup/Popup';
import { appContext } from 'views/App';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
  min-height: 670px;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BankDetails = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;

  const { t } = useTranslation();
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, getValues, setValue } = methods;
  const navigate = useNavigate();
  const params = useParams();
  const [isEditPage, setIsEditPage] = useState<boolean>(false);
  const [isSavePopupOpened, setIsSavePopupOpened] = useState<boolean>(false);

  useEffect(() => {
    if (params?.id) setIsEditPage(true);
  }, [params]);

  useEffect(() => {
    if (isEditPage) {
      setValue('bankAccountName', userData?.billingInformations[0]?.name);
      setValue('bankName', userData?.billingInformations[0]?.bankName);
      setValue('iban', userData?.billingInformations[0]?.iban);
    }
  }, [isEditPage, userData]);

  const [
    createUserBillingInformation,
    { loading: createUserBillingInformationLoading },
  ] = useMutation(CREATE_USER_BILLING_INFORMATION, {
    onCompleted: (data) => {
      if (!data) return;
      navigate('/success-bank-details');
    },
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const [
    updateUserBullingInformation,
    { loading: updateUserBullingInformationLoading },
  ] = useMutation(UPDATE_USER_BILLING_INFORMATION, {
    onCompleted: (data) => {
      if (!data) return;
      setIsSavePopupOpened(true);
    },
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const onSubmit = () => {
    if (isEditPage) {
      updateUserBullingInformation({
        variables: {
          id: params?.id,
          name: getValues('bankAccountName'),
          bankName: getValues('bankName'),
          accountNumber: ' ',
          iban: getValues('iban'),
        },
      });
    } else {
      createUserBillingInformation({
        variables: {
          name: getValues('bankAccountName'),
          bankName: getValues('bankName'),
          accountNumber: ' ',
          iban: getValues('iban'),
        },
      });
    }
  };

  useEffect(() => {
    setIsLoading(
      createUserBillingInformationLoading ||
        updateUserBullingInformationLoading,
    );
  }, [
    createUserBillingInformationLoading,
    updateUserBullingInformationLoading,
  ]);

  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('bankDetails')}
      outsideElements={
        <Popup
          isPopupOpened={isSavePopupOpened}
          onDecline={() => setIsSavePopupOpened(false)}
          content={t('confirmChangeSave')}
          confirmText="ok"
          type="info"
        />
      }
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledContainer>
            <Input
              mode="light"
              name="bankAccountName"
              label={t('bankAccountName')}
              placeholder={t('bankAccountNameePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
            />
            <Input
              mode="light"
              name="bankName"
              label={t('bankName')}
              placeholder={t('bankNamePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
            />
            <Input
              mode="light"
              name="iban"
              label={t('iban')}
              placeholder={t('ibanPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
            />
          </StyledContainer>
          <Button type="submit">{t('save')}</Button>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};

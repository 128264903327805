import { Layout } from 'components/Layout/Layout';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_LICENSE } from 'graphql/mutations/createLicense';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LICENSE } from 'graphql/queries/license';
import { UPDATE_LICENSE } from 'graphql/mutations/updateLicense';
import { Popup } from 'components/Popup/Popup';
import { DELETE_LICENSE } from 'graphql/mutations/deleteLicense';
import { appContext } from 'views/App';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .deleteButton {
    margin-bottom: 16px;
  }
`;

export const LicenseDetails = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const methods = useForm({ mode: 'onSubmit' });
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty },
    watch,
  } = methods;
  const navigate = useNavigate();
  const { id } = useParams();
  const [license, { data, loading: licenseLoading }] = useLazyQuery(LICENSE);
  const [createLicense, { loading: createLicenseLoading }] = useMutation(
    CREATE_LICENSE,
    {
      onCompleted: () => {
        navigate('/success-license');
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );
  const [updateLicense, { loading: updateLicenseLoading }] = useMutation(
    UPDATE_LICENSE,
    {
      onCompleted: () => setIsSavePopupOpened(true),
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );
  const [deleteLicense, { loading: deleteLicenseLoading }] = useMutation(
    DELETE_LICENSE,
    {
      onCompleted: () => navigate('/licenses'),
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );
  const [isSavePopupOpened, setIsSavePopupOpened] = useState<boolean>(false);
  const [isDeletePopupOpened, setIsDeletePopupOpened] =
    useState<boolean>(false);

  const onSubmit = () => {
    const variables = {
      plate: getValues('licensePlate'),
      beneficiary: 'beneficaryName',
      carName: 'carModel',
    };
    if (id) {
      isDirty &&
        updateLicense({
          variables: {
            ...variables,
            id,
          },
        });
    } else {
      createLicense({
        variables: {
          ...variables,
          userId: userData.id,
        },
      });
    }
  };

  useEffect(() => {
    if (!id) return;
    license({ variables: { id } });
  }, [id]);

  useEffect(() => {
    if (!data) return;
    setValue('licensePlate', data.license.plate);
  }, [data]);

  useEffect(() => {
    setIsLoading(
      createLicenseLoading ||
        licenseLoading ||
        updateLicenseLoading ||
        deleteLicenseLoading,
    );
  }, [
    createLicenseLoading,
    licenseLoading,
    updateLicenseLoading,
    deleteLicenseLoading,
  ]);

  useEffect(() => {
    const license = watch('licensePlate').toUpperCase().replaceAll(' ', '');
    setValue('licensePlate', license);
  }, [watch('licensePlate')]);
  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t(id ? 'editLicense' : 'addLicenseDetails')}
      outsideElements={
        <div>
          <Popup
            type="info"
            content={t('changeSave')}
            isPopupOpened={isSavePopupOpened}
            onDecline={() => setIsSavePopupOpened(false)}
            confirmText="Ok"
          />
          <Popup
            type="action"
            content={t('deleteLicenceConfirm')}
            isPopupOpened={isDeletePopupOpened}
            onDecline={() => setIsDeletePopupOpened(false)}
            onConfirm={() => deleteLicense({ variables: { id } })}
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id="new-license-form">
          <div>
            <Input
              mode="light"
              name="licensePlate"
              label={t('licensePlate')}
              placeholder={t('licensePlatePlaceholder')}
              isRequired
              type="text"
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            {id && (
              <Button
                mode="inverted"
                onBtnClick={() => setIsDeletePopupOpened(true)}
                className="deleteButton"
              >
                {t('deleteLicense')}
              </Button>
            )}
          </div>
          <Button type="submit" disabled={id && !isDirty}>
            {t('save')}
          </Button>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};

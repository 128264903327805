import { useLazyQuery } from '@apollo/client';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'styles/devices';
import { REPORT_BY_QR_CODE } from 'graphql/queries/reportByQRCode';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useTranslation } from 'react-i18next';
import { ReportProps } from 'types/Report';
import { appContext } from 'views/App';

const StyledForm = styled.form<{ height: string }>`
  p {
    line-height: 16px;
  }
  min-height: ${({ height }) => `calc(${height} - 80px - 60px)`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media ${device.laptop} {
    min-height: ${({ height }) => `calc(${height} - 147px - 92px)`};
  }
  .detailTitle {
    @media ${device.laptop} {
      font-size: var(--text-l);
      padding-bottom: 5px;
    }
  }
  .detailContent {
    @media ${device.laptop} {
      font-size: 22px;
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .nextButton {
    color: var(--white);
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;
const StyledDatailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media ${device.laptop} {
    gap: 25px;
  }
`;

const StyledDetail = styled.div``;
const StyledDetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 35px;
  @media ${device.laptop} {
    gap: 30px;
  }
`;
const StyledContent = styled.div`
  max-width: 793px;
  @media ${device.laptop} {
    h2 {
      font-size: 40px;
    }
  }
`;

export const TicketDetails = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const methods = useForm();
  const params = useParams();
  const navigate = useNavigate();
  const { windowHeight } = useWindowHeight();
  const [reportData, setReportData] = useState<ReportProps | undefined>(
    undefined,
  );
  const [reportByQRCode, { loading }] = useLazyQuery(REPORT_BY_QR_CODE, {
    onCompleted: (data) => {
      setReportData(data.reportByQRCode);
    },
    onError: (error) => {
      setIsError(true);
      throw new Error(error.message);
    },
  });
  useEffect(() => {
    const qrCodeId = params?.qrCodeId;
    if (!qrCodeId) return;
    sessionStorage.setItem('qrCodeId', qrCodeId);
    reportByQRCode({ variables: { qrCodeId: qrCodeId } });
  }, [params]);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <ParkingTicketsLayout>
      <FormProvider {...methods}>
        <StyledForm height={`${windowHeight}px`}>
          <StyledContent>
            <Heading color="var(--black-always)">
              {t('ticketDetailsTitle')}
            </Heading>
            <StyledDetailsList>
              <StyledDatailsContainer>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {t('ticketDetaildNumber')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black-always)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    {reportData?.qrCodeId || ''}
                  </Paragraph>
                </StyledDetail>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    textAlign="right"
                    className="detailTitle"
                  >
                    {t('plate')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black-always)"
                    weight="bolder"
                    dimension="m"
                    textAlign="right"
                    className="detailContent"
                  >
                    {reportData?.plate || ''}
                  </Paragraph>
                </StyledDetail>
              </StyledDatailsContainer>
              <StyledDetail>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('countryCode')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  CH
                </Paragraph>
              </StyledDetail>
              <StyledDetail>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('notes')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  {reportData?.notes || ''}
                </Paragraph>
              </StyledDetail>
              <StyledDetail>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('amount')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  CHF {reportData?.fineValue}
                </Paragraph>
              </StyledDetail>
            </StyledDetailsList>
            <Heading color="var(--black-always)">
              {t('placeAndDateOfTheParkingViolation')}
            </Heading>
            <StyledDetailsList>
              <StyledDatailsContainer>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {t('streetAndStreetNumber')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black-always)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    {reportData?.street || ''} {reportData?.streetNumber || ''}
                  </Paragraph>
                </StyledDetail>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    textAlign="right"
                    className="detailTitle"
                  >
                    {t('zipCodeAndCity')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black-always)"
                    weight="bolder"
                    dimension="m"
                    textAlign="right"
                    className="detailContent"
                  >
                    {reportData?.zipCode || ''} {reportData?.city || ''}
                  </Paragraph>
                </StyledDetail>
              </StyledDatailsContainer>
              <StyledDetail>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('timeAndDate')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  {reportData?.createdAt}
                </Paragraph>
              </StyledDetail>
            </StyledDetailsList>
          </StyledContent>
          <StyledButtonContainer>
            <Button
              mode="inverted"
              onBtnClick={() =>
                navigate(`/car-driver-details/${reportData?.qrCodeId}`)
              }
            >
              {t('cardriverDetailsTitle')}
            </Button>
            <Button
              mode="inverted"
              onBtnClick={() =>
                navigate(`/objection-raise/${reportData?.qrCodeId}`)
              }
            >
              {t('objection')}
            </Button>
            <Button
              className="nextButton"
              onBtnClick={() =>
                navigate(`/payment-information/${reportData?.qrCodeId}`)
              }
            >
              {t('payNow')}
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>
    </ParkingTicketsLayout>
  );
};

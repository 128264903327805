import { Layout } from 'components/Layout/Layout';
import { Input } from 'components/Input/Input';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { PhoneNumberInput } from 'components/PhoneNumberInput/PhoneNumberInput';
import { UPDATE_USER } from 'graphql/mutations/updateUser';
import { Popup } from 'components/Popup/Popup';
import { useNavigate } from 'react-router-dom';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { DisabledInput } from 'components/DisabledInput/DisabledInput';
import { EditProfileTopBar } from './EditProfileTopBar/EditProfileTopBar';
import { EditProfileBottomContainer } from './EditProfileBottomContainer/EditProfileBottomContainer';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const EditProfile = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setUserData = appContextStore?.setUserData;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const methods = useForm({
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const { setValue, getValues } = methods;
  const { mutate } = useUploadMutation();

  useEffect(() => {
    if (!userData) return;
    if (!getValues('firstName')) setValue('firstName', userData?.firstName);
    if (!getValues('lastName')) setValue('lastName', userData?.lastName);
    if (!getValues('phoneNumber'))
      setValue('phoneNumber', userData?.phoneNumber);
    if (!getValues('companyName'))
      setValue('companyName', userData?.companyName);
    if (!getValues('companyAddress'))
      setValue('companyAddress', userData?.companyAddress);
    setValue('email', userData?.email);
  }, [userData]);

  const handleUserUpdate = (avatar?: File) => {
    setIsLoading(true);
    const firstName = getValues('firstName');
    const lastName = getValues('lastName');
    const phoneNumber = getValues('phoneNumber');
    const uploadedImage = avatar;
    const phonePrefix = getValues('phonePrefix');
    const companyName = getValues('companyName');
    const companyAddress = getValues('companyAddress');
    mutate(UPDATE_USER, {
      variables: {
        firstName,
        lastName,
        phoneNumber,
        phonePrefix,
        uploadedImage,
        companyName: companyName ? companyName : '',
        companyAddress: companyAddress ? companyAddress : '',
      },
    })
      .then((result) => {
        if (result) {
          setIsLoading(false);
          const data = result.data;
          const firstName = data.updateUser.firstName;
          const lastName = data.updateUser.lastName;
          const phoneNumber = data.updateUser.phoneNumber;
          const phonePrefix = data.updateUser.phonePrefix;
          const avatar = data.updateUser.avatar;
          const companyName = data.updateUser.companyName;
          const companyAddress = data.updateUser.companyAddress;
          setUserData({
            ...userData,
            firstName,
            lastName,
            phoneNumber,
            phonePrefix,
            avatar,
            companyName,
            companyAddress,
          });
          setIsPopupOpened(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        throw new Error(error);
      });
  };

  return (
    <Layout
      isMenuBar
      userBarContent={
        <EditProfileTopBar onSubmit={(avatar) => handleUserUpdate(avatar)} />
      }
      isBackBtn
      backButtonMode="text"
      viewTitle={t('editProfile')}
      isWithBottomPadding={false}
      outsideElements={
        <Popup
          isPopupOpened={isPopupOpened}
          onDecline={() => navigate('/profile')}
          content={t('confirmChangeSave')}
          confirmText="ok"
          type="info"
        />
      }
    >
      <StyledContainer>
        <FormProvider {...methods}>
          <form>
            <Input
              name="firstName"
              label={t('firstNameLabel')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              mode="light"
              bottomMarting="16"
            />
            <Input
              name="lastName"
              label={t('lastNameLabel')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              mode="light"
              bottomMarting="16"
            />
            <Input
              name="companyName"
              label={t('companyName')}
              type="text"
              mode="light"
              bottomMarting="16"
            />
            <Input
              name="companyAddress"
              label={t('companyAddress')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              mode="light"
              bottomMarting="16"
            />
            <PhoneNumberInput
              mode="light"
              phonePrefix={userData?.phonePrefix}
              handlePrefixChange={(prefix) => setValue('phonePrefix', prefix)}
              bottomMarting="16"
            />
            <DisabledInput
              name={'email'}
              label={t('emailLabel')}
              mode="light"
            />
          </form>
        </FormProvider>
        <EditProfileBottomContainer />
      </StyledContainer>
    </Layout>
  );
};

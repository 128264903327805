import { useLazyQuery } from '@apollo/client';
import { Layout } from 'components/Layout/Layout';
import { ReportList } from 'components/ReportList/ReportList';
import { REPORTS_OF_USER } from 'graphql/queries/reportsOfUser';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { GAS_STATION_REPORTS_OF_USER } from 'graphql/queries/gasStationReportsOfUser';
import { getPaymentStatusIndex } from 'utils/getPaymentStatusIndex';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { EditClientDetails } from 'views/Clients/ClientReports/EditClientDetails/EditClientDetails';
import { ClientTopBar } from './ClientTopBar/ClientTopBar';
import { ClientReportsHeading } from './ClientReportsHeading/ClientReportsHeading';
import { appContext } from 'views/App';

const StyledConatiner = styled.div`
  margin: 10px 0;
`;

export const ClientReports = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [userReports, setUserReports] = useState([]);
  const params = useParams();
  const [page, setPage] = useState<number>(1);
  const [hasMorePages, setHasMorePages] = useState<boolean>(true);
  const [isContentEnd, setIsContentEnd] = useState<boolean>(false);
  const [isGasStationReportsSelected, setIsGasStationReportsSelected] =
    useState<boolean>(false);
  const [isClientDetailsClose, setIsClientDetailsClose] =
    useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [reportsOfUser, { loading, fetchMore: fetchMoreReportsOfUser }] =
    useLazyQuery(REPORTS_OF_USER, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (!data) return;
        setUserReports(data.reportsOfUser.data);
        setPage(1);
        setHasMorePages(data.reportsOfUser.paginatorInfo.hasMorePages);
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    });
  const [
    gasStationReportsOfUser,
    {
      loading: gasStationReportsOfUserLoading,
      fetchMore: fetchMoreGasStationReportsOfUser,
    },
  ] = useLazyQuery(GAS_STATION_REPORTS_OF_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;
      setUserReports(data.gasStationReportsOfUser.data);
      setPage(1);
      setHasMorePages(data.gasStationReportsOfUser.paginatorInfo.hasMorePages);
    },
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  useEffect(() => {
    if (!params) return;
    if (isGasStationReportsSelected) {
      gasStationReportsOfUser({
        variables: {
          user: params?.id,
          first: 5,
          page: 1,
          idOrPlate: searchValue || '',
          paymentStatus: getPaymentStatusIndex(status) || undefined,
        },
      });
    } else {
      reportsOfUser({
        variables: {
          user: params?.id,
          first: 5,
          page: 1,
          idOrPlate: searchValue || '',
          paymentStatus: getPaymentStatusIndex(status) || undefined,
        },
      });
    }
  }, [params, searchValue, status, isGasStationReportsSelected]);

  useEffect(() => {
    if (!isContentEnd) return;
    if (hasMorePages) {
      setIsLoading(true);
      if (isGasStationReportsSelected) {
        fetchMoreGasStationReportsOfUser({
          variables: {
            user: params?.id,
            first: 5,
            page: page + 1,
            idOrPlate: searchValue || '',
            paymentStatus: getPaymentStatusIndex(status) || undefined,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            setIsLoading(false);
            setPage((prev) => prev + 1);
            setUserReports((prev) => [
              ...prev,
              ...fetchMoreResult.gasStationReportsOfUser.data,
            ]);
            setHasMorePages(
              fetchMoreResult.gasStationReportsOfUser.paginatorInfo
                .hasMorePages,
            );
          },
        });
      } else {
        fetchMoreReportsOfUser({
          variables: {
            user: params?.id,
            first: 5,
            page: page + 1,
            idOrPlate: searchValue || '',
            paymentStatus: getPaymentStatusIndex(status) || undefined,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            setIsLoading(false);
            setPage((prev) => prev + 1);
            setUserReports((prev) => [
              ...prev,
              ...fetchMoreResult.reportsOfUser.data,
            ]);
            setHasMorePages(
              fetchMoreResult.reportsOfUser.paginatorInfo.hasMorePages,
            );
          },
        });
      }
    }
  }, [isContentEnd]);

  useEffect(() => {
    setIsLoading(loading || gasStationReportsOfUserLoading);
  }, [loading, gasStationReportsOfUserLoading]);

  return (
    <Layout
      userBarContent={
        <ClientTopBar
          handleSearchValueChange={(searchValue) => setSearchValue(searchValue)}
          handleStatusChange={(status) => setStatus(status)}
        />
      }
      isMenuBar
      mode="one-third"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('clientReports')}
      handleContentEnd={(isContentEnd) => setIsContentEnd(isContentEnd)}
      outsideElements={
        <EditClientDetails
          isClose={isClientDetailsClose}
          handleCloseStatusChange={(isClose) =>
            setIsClientDetailsClose(isClose)
          }
        />
      }
    >
      <ClientReportsHeading
        handleIsClientDetailsClose={() => setIsClientDetailsClose(false)}
      />
      <StyledConatiner>
        <Dropdown
          options={clientsReportTypes}
          label={t('reportType')}
          handleSelectedOption={(selectedOption) => {
            if (selectedOption === clientsReportTypes[0]) {
              setIsGasStationReportsSelected(false);
            } else {
              setIsGasStationReportsSelected(true);
            }
          }}
          mode="full"
        />
      </StyledConatiner>
      <ReportList reportList={userReports} />
    </Layout>
  );
};
const clientsReportTypes = ['towingTickets', 'gasStationButtonTitle'];

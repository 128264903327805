import { AccordionElement } from '../AccordionElement/AccordionElement';
import { AccordionElementButton } from '../AccordionElementButton/AccordionElementButton';

interface AccordionElementListProps {
  isAccordionOpened?: boolean;
  accordionElements?: string[];
  selectedAccordionElements?: string[];
  onTitleClick?: (element: string) => void;
  isAllElementClick?: boolean;
  onElementClick?: (element: string) => void;
  accordionElementTitle?: string;
  isWithSelectIcon?: boolean;
  addButtonTitle?: string;
  onAddButtonClick?: () => void;
}

export const AccordionElementList = ({
  isAccordionOpened,
  accordionElements,
  selectedAccordionElements,
  onTitleClick,
  isAllElementClick,
  onElementClick,
  accordionElementTitle,
  isWithSelectIcon,
  addButtonTitle,
  onAddButtonClick,
}: AccordionElementListProps) => {
  if (!isAccordionOpened) return;
  return (
    <ul>
      {accordionElements &&
        accordionElements.map((element, i) => {
          const isSelected = Boolean(
            selectedAccordionElements?.find((item) => item === element),
          );
          return (
            <AccordionElement
              key={i}
              isElementSelected={isSelected}
              element={element}
              onTitleClick={onTitleClick}
              isAllElementClick={isAllElementClick}
              onElementClick={onElementClick}
              accordionElementTitle={accordionElementTitle}
              isWithSelectIcon={isWithSelectIcon}
            />
          );
        })}
      <AccordionElementButton
        addButtonTitle={addButtonTitle}
        onAddButtonClick={onAddButtonClick}
      />
    </ul>
  );
};

import { AuthProvider } from 'context/auth';
import ThemeProvider from 'context/ThemeContext';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import GlobalStyle from 'styles/GlobalStyle';
import './i18n/config';
import { registerSw } from 'registerSw';
import { App } from 'views/App';
import * as Sentry from '@sentry/react';
import { AppRoutes } from 'AppRoutes';
import React from 'react';

registerSw();

Sentry.init({
  dsn: 'https://b67d43aa30674d8294e8ec0dbe92bdf7@o568102.ingest.sentry.io/4505424970645504',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [/^https:\/\/app\.falsch-parker\.ch/],
    }),
  ],

  tracesSampleRate: 1.0,
  initialScope: {
    tags: { 'sw-version': process.env.REACT_APP_SW_VERSION },
  },
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <BrowserRouter>
          <App>
            <AppRoutes />
            <GlobalStyle />
          </App>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
